import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  &:last-children {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  display: flex;
  gap: 10px;
  .title {
    flex: 1;
    font-weight: bold;
    font-size: 1.3em;
  }
  .subtitle {
    font-size: 0.94em;
    font-weight: normal;
  }
`;

const List = styled.div`
  padding: 0px 20px 20px 20px;
  display: grid;
  gap: 10px;
`;

const MemberWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 2px 20px;
  position: relative;
  .capitalize {
    text-transform: capitalize;
  }
  .actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    padding: 10px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #444;
      font-size: 0.88em;
    }
  }
`;

const Member = ({
  name = '',
  firstName = '',
  middleName = '',
  lastName = '',
  phone = [],
  relationship,
  addedBy,
  email,
  onRemove,
  onEdit
}) => {
  return (
    <MemberWrapper>
      <div>
        <p>
          <strong>Name</strong> <br />
          {[name, firstName, middleName, lastName].filter((name) => name).join(' ')}
        </p>
      </div>
      {relationship && (
        <div>
          <p>
            <strong>Relationship</strong> <br />
            <span className="capitalize">{relationship}</span>
          </p>
        </div>
      )}
      {phone[0]?.number && (
        <div>
          <p>
            <strong>Phone</strong> <br />
            {phone[0]?.number}
          </p>
        </div>
      )}
      {email && (
        <div>
          <p>
            <strong>Email</strong> <br />
            {email}
          </p>
        </div>
      )}
      {addedBy && (
        <div>
          <p>
            <strong>Added by</strong> <br />
            {addedBy}
          </p>
        </div>
      )}
      <div className="actions">
        {onRemove && (
          <Button onClick={onRemove} title="Remove">
            <Icon name="fa-solid fa-trash" />
          </Button>
        )}
        {onEdit && (
          <Button onClick={onEdit} title="Edit">
            <Icon name="fa-solid fa-pen-to-square" />
          </Button>
        )}
      </div>
    </MemberWrapper>
  );
};

export default ({ title, types, application, members = [], onAdd, onRemove, onEdit, subtitle }) => {
  const otherMembers = React.useMemo(() => {
    let contactMembers = [
      !application?.relatedAppData?.parentApplication
        ? null
        : {
            type: 'resident',
            applicationId: application?.relatedAppData?.parentApplication?.applicationId,
            name: application?.relatedAppData?.parentApplication?.name,
            addedBy: application?.relatedAppData?.parentApplication?.name
          }
    ]
      .concat(
        application?.relatedAppData?.otherContacts?.map((r) => ({
          type: r.type,
          addedBy: r.addedBy,
          firstName: r.firstName,
          lastName: r.lastName,
          readOnly: true
        })) || []
      )
      .filter((r) => r && types?.includes(r.type) && r.applicationId !== application._id);

    if (types?.includes('minor') && application?.relatedAppData?.minors?.length) {
      contactMembers = contactMembers.concat(application?.relatedAppData?.minors);
    }

    return contactMembers;
  }, []);
  const hasMembers = members.length > 0 || otherMembers.length > 0;
  const { t } = useTranslation();

  if (!onAdd && !hasMembers) return <></>;

  return (
    <Wrapper>
      <Header>
        {onAdd && (
          <Icon
            name={hasMembers ? 'fa-duotone fa-circle-check' : 'fa-light fa-circle'}
            color={hasMembers ? 'green' : '#ccc'}
          />
        )}
        <div className="title">
          <div>{title}</div>
          {subtitle && <div className="subtitle">{subtitle}</div>}
        </div>
        {onAdd && (
          <Button variant="primary" onClick={onAdd}>
            {t('buttonsActions.add')}
          </Button>
        )}
      </Header>
      {hasMembers && (
        <List>
          {otherMembers.map((member) => (
            <Member key={member.uuid} {...member} />
          ))}
          {members.map((member) => (
            <Member
              key={member.uuid}
              {...member}
              onEdit={() => onEdit(member)}
              onRemove={() => onRemove(member)}
            />
          ))}
        </List>
      )}
    </Wrapper>
  );
};
