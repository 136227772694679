import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { AUDIENCES } from 'helpers/enums';
import useShowing from '../hooks/useShowing';
import Layout from '../../layout/step-details';
import SectionList from './section-list';
import ActionStatus from './action-status';
import LocationList from '../../locations-list';

const Content = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ isMobile }) => (isMobile ? '10px;' : '20px;')};
`;
const Title = styled.div`
  font-size: 1.75em;
  text-align: center;
  font-weight: bold;
`;
const mobileMaxWidth = 960;

export default ({ updateChatClient }) => {
  const [showLocationList, setShowLocation] = React.useState();
  const { showingId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth });
  const { navigate, dataFetched, steps, action, handleActions, location } = useShowing({
    showingId,
    updateChatClient,
    setShowLocation
  });

  React.useEffect(() => {
    if (action?.applicationType === AUDIENCES.applicant.applicationType) {
      navigate(AUDIENCES.applicant.paths.detail(action._id), AUDIENCES.applicant.uuid);
    }
  }, [action?.applicationType]);

  return (
    <Layout
      onChangeProperty={() => setShowLocation(true)}
      mobileMaxWidth={mobileMaxWidth}
      loading={!dataFetched}
      hideProperty={isMobile && showLocationList}
      onBack={showLocationList ? () => setShowLocation(false) : undefined}
      backContext={showLocationList ? 'overview' : undefined}
      locationId={action?.locationId}
      type={'showing'}
      data={action}
      handleActions={handleActions}>
      {showLocationList ? (
        <Content>
          <LocationList
            hideHeader={true}
            hideDefaultLocation={false}
            selectContent={'Request showing'}
            onSelect={(location) => {
              handleActions({
                id: 'save',
                data: { type: 'change-location', content: { locationId: location?._id } }
              });
              setShowLocation(false);
            }}
          />
        </Content>
      ) : (
        <Content isMobile={isMobile}>
          {!isMobile && (
            <Title>
              <Trans i18nKey="layouts.showing.title">Request a tour</Trans>
            </Title>
          )}
          {!isMobile && (
            <ActionStatus action={action} isMobile={isMobile} handleActions={handleActions} />
          )}
          <SectionList
            data={action}
            steps={steps}
            handleActions={handleActions}
            isMobile={isMobile}
            location={location}
          />
          {isMobile && (
            <ActionStatus action={action} isMobile={isMobile} handleActions={handleActions} />
          )}
        </Content>
      )}
    </Layout>
  );
};
