import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { useUiState } from 'state';
import { useAPIData } from 'dal/useAPIData';
import useAudience from 'hooks/useAudience';
import Icon from 'components/icon';
import Property from 'components/property';
import Navbar from './nav-bar';
import { LAYOUTS } from './configs';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 960px) {
    background-color: #fff;
  }
`;

const Form = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  @media (max-width: 960px) {
    padding: 0;
  }
`;

const FormContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  @media (max-width: 960px) {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    border: none;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 0 20px 20px;
  @media (max-width: 960px) {
    padding: 0;
    overflow-y: auto;
    white-space: normal;
    text-wrap: wrap !important;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

const StyledLoader = styled(Icon)`
  width: 100%;
  margin: auto;
  flex-shrink: 0;
  ${({ isMobile }) => (isMobile ? '' : `max-width:375px;`)}
`;

const StyledProperty = styled(Property)`
  width: 100%;
  flex-shrink: 0;
  ${({ isMobile }) => (isMobile ? '' : `max-width:375px;`)}
`;

const ContentLoading = () => {
  return <StyledLoader name="fa-duotone fa-spinner-third fa-spin" size={96} />;
};

const PropertyPanel = ({
  isMobile,
  hideWhenNoLocation,
  locationId,
  onChangeProperty,
  hideActions
}) => {
  const [audience] = useAudience();
  const contextId = locationId;
  const [data, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_CONTEXT',
    !contextId ? undefined : { contextId: contextId, audience: audience?.uuid },
    { reFetchWhen: [contextId || ''], disableEmptyArgs: true }
  );

  if (!contextId && hideWhenNoLocation) return <></>;

  if (!dataFetched) {
    return (
      <StyledLoader name="fa-duotone fa-spinner-third fa-spin" size={100} isMobile={isMobile} />
    );
  }

  return (
    <StyledProperty
      location={data || {}}
      forceDisplay={true}
      isMobile={isMobile}
      hideActions={hideActions}
      onChangeProperty={onChangeProperty}
    />
  );
};

export default ({
  loading,
  type = 'showing',
  children,
  hideProperty,
  mobileMaxWidth = 960,
  data,
  ...props
}) => {
  const params = useParams();
  const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth });
  const { updateUiState } = useUiState();

  const layout = React.useMemo(() => LAYOUTS[type]({ ...params, ...data }), [type]);

  React.useEffect(() => {
    if (!isMobile) return;
    updateUiState({ hideHeader: true });
    return () => updateUiState({ hideHeader: false });
  }, [isMobile]);

  React.useEffect(() => {
    updateUiState({ menuOpen: false });
  }, []);

  const content = loading ? <ContentLoading /> : children;

  return (
    <Wrapper>
      {!isMobile && !hideProperty && <PropertyPanel isMobile={isMobile} {...props} />}
      <Form>
        <FormContainer>
          <Navbar layout={layout} data={data} isMobile={isMobile} {...props} />
          {isMobile && !hideProperty && (
            <PropertyPanel isMobile={isMobile} hideActions={isMobile} {...props} />
          )}
          <Content>{content}</Content>
        </FormContainer>
      </Form>
    </Wrapper>
  );
};
