import React from 'react';
import styled from 'styled-components';
import Block from './block';
import { useDocumentContext } from '../../state';
import Toolbar from './toolbar';

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
`;

const Scrollable = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  overflow: auto;
  background-color: #eee;
`;

const Paper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  outline: 1px solid #ddd;
  background-color: #fff;
  flex-shrink: 0;
  position: relative;
  padding: 0px;
  box-sizing: border-box;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  scale: ${({ zoom }) => zoom}%;
  transform-origin: top center;
  * {
    box-sizing: border-box;
  }
`;

const ZoomButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30px;
  right: 30px;
  height: 70px;
  width: 35px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  .btn-zoom {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    cursor: pointer;
    flex: 1;
    &:first-child {
      border-bottom: 1px solid #eee;
    }
    &:hover {
      background-color: #000;
    }
  }
  @media (max-width: 500px) {
    bottom: 20px;
    right: 15px;
    z-index: 3000;
  }
  overflow: hidden;
`;

const ImgPlaceholder = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

const Sizer = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ width }) => width}px;
  margin: 0 auto;
  padding: 20px 0;
`;

const getPageSize = ({ page }) => {
  const { scale = 1 } = page;
  const renderScale = 1.33;
  const width = Math.floor(page.width / scale);
  const height = Math.floor(page.height / scale);
  return {
    pageWidth: scale === 1 ? page.width : Math.floor(width * renderScale),
    pageHeight: scale === 1 ? page.height : Math.floor(height * renderScale)
  };
};

export const Page = ({
  page,
  pageUrl,
  blocks = [],
  updateBlock,
  users,
  zoom,
  focusedBlock,
  setFocusedBlock
}) => {
  const pageRef = React.useRef(null);
  const { pageWidth, pageHeight } = getPageSize({ page });
  const [sizer, setSizer] = React.useState(pageWidth);

  React.useEffect(() => {
    pageRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }, []);

  React.useEffect(() => {
    const rect = pageRef.current.getBoundingClientRect();
    setSizer(rect.width);
  }, [zoom]);

  return (
    <Sizer width={sizer}>
      <Paper ref={pageRef} width={pageWidth} height={pageHeight} className="pagePaper" zoom={zoom}>
        {pageUrl && <ImgPlaceholder width={pageWidth} height={pageHeight} src={pageUrl} alt="" />}
        {blocks.map((block, i) => (
          <Block
            key={i}
            block={block}
            users={users}
            updateBlock={updateBlock}
            focusedBlock={focusedBlock}
            setFocusedBlock={setFocusedBlock}
            page={page}
          />
        ))}
      </Paper>
    </Sizer>
  );
};

export default () => {
  const { ui, doc, user } = useDocumentContext();
  const { currentPage: page } = ui;
  const [blocks, setBlocks] = React.useState([]);
  const containerRef = React.useRef(null);
  const [zoom, setZoom] = React.useState(100);

  React.useEffect(() => {
    if (!page?.uuid) return;
    ui?.updatePage({ uuid: page.uuid, blocks });
  }, [blocks]);

  React.useEffect(() => {
    if (!page?.uuid) return;
    setBlocks(page.blocks || []);
  }, [page?.uuid]);

  const updateBlock = (block) => {
    if (Array.isArray(block)) {
      const uuids = block.map((b) => b.uuid);
      setBlocks((blocks) =>
        blocks.map((b) => {
          if (uuids.includes(b.uuid) === false) return b;
          const update = block.find((block) => block.uuid === b.uuid);
          return { ...b, ...update };
        })
      );
      return;
    }
    setBlocks((blocks) =>
      blocks.map((b) => {
        if (b.uuid !== block.uuid) return b;
        return { ...b, ...block };
      })
    );
  };

  React.useEffect(() => {
    const containerWidth = window.innerWidth;
    const { pageWidth, pageHeight } = getPageSize({ page });
    if (containerWidth < pageWidth) {
      const relativeZoom = Math.round((containerWidth * 100) / pageWidth) - 2;
      setZoom(relativeZoom);
    } else {
      containerRef.current.scrollTop = 0;
    }
  }, [page.uuid]);

  const handleZoom = (dir) => {
    const newZoom = dir === 'out' ? zoom - 10 : zoom + 10;
    setZoom(newZoom < 0 ? 10 : newZoom);
  };

  return (
    <Wrapper>
      {!user.hasSigned && !ui.allSigned && user.requireSignature && !doc.isExpired && (
        <Toolbar blocks={ui.fillableBlocks} setFocusedBlock={ui.setFocusedBlock} />
      )}
      <Scrollable ref={containerRef}>
        <Page
          page={page}
          pageUrl={page.url}
          blocks={blocks}
          users={doc.recipients}
          updateBlock={updateBlock}
          zoom={zoom}
          focusedBlock={ui.focusedBlock}
          setFocusedBlock={(uuid) => ui.updateUi({ focusedBlock: uuid })}
        />
      </Scrollable>
      <ZoomButtons>
        <div className="btn-zoom" onClick={() => handleZoom('in')}>
          <i className="fa-regular fa-magnifying-glass-plus" />
        </div>
        <div className="btn-zoom" onClick={() => handleZoom('out')}>
          <i className="fa-regular fa-magnifying-glass-minus" />
        </div>
      </ZoomButtons>
    </Wrapper>
  );
};
