import React from 'react';
import styled from 'styled-components';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { withScriptjs } from 'react-google-maps';
import { encodeGoogleAddress } from 'helpers/geocoding';
import Input from './input';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-self: stretch;
  position: relative;
  width: 100%;
`;

const formatAddress = (places) => {
  if (!places || !places[0]) {
    return;
  }
  return encodeGoogleAddress(places[0]);
};

const SearchBox = withScriptjs(({ searchRef, onBlur, name, address, setAddress }) => {
  const [localAddress, setLocalAddress] = React.useState(address);
  const { t } = useTranslation();

  React.useEffect(() => {
    setAddress && setAddress(localAddress);
  }, [localAddress]);

  return (
    <StandaloneSearchBox
      ref={searchRef}
      onPlacesChanged={(e) => {
        const places = searchRef.current.getPlaces();
        setLocalAddress(formatAddress(places));
      }}>
      <Input
        name={name}
        onBlur={onBlur}
        onChange={(ev) => {
          const { value } = ev.target;
          if (value === '') return setLocalAddress(undefined);
          setLocalAddress({ ...(localAddress || {}), formattedAddress: value });
        }}
        placeholder={t('searchAddress')}
        defaultValue={localAddress?.formattedAddress || ''}
        className={'address-search-box'}
      />
    </StandaloneSearchBox>
  );
});

const Address = ({ name, onBlur, value, onChange, className }) => {
  const searchRef = React.useRef();
  return (
    <Wrapper className={className}>
      <SearchBox
        searchRef={searchRef}
        name={name}
        onBlur={onBlur}
        setAddress={onChange}
        address={value}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
      />
    </Wrapper>
  );
};

export default Address;

export const AddressInput = ({ value, onChange, className, name, onBlur }) => {
  return (
    <Address
      className={className}
      value={value}
      name={name}
      onChange={(addr) => onChange?.({ target: { name, value: addr } })}
      onBlur={onBlur}
    />
  );
};
