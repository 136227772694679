import React from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';

import { UserContext, CompanyContext, useNavigateWithContext } from 'state';
import { SAVE_APPLICATION_ITEM } from 'dal/application-items';
import { AUDIENCES } from 'helpers/enums';
import { ADD_FILE } from 'dal/applications';
import { GET_PAYMENT_SESSION } from 'dal/payments';
import { REDEEM_CODE } from 'dal/applications';
import { useAPIData } from 'dal/useAPIData';
import { STEP_STATES } from 'helpers/enums';
import ActionSteps from './action-steps';

const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export default ({ showingId, updateChatClient, setShowLocation }) => {
  const { userContext } = React.useContext(UserContext);
  const { companyContext } = React.useContext(CompanyContext);
  const navigate = useNavigateWithContext();

  const [refreshKey, setRefreshKey] = React.useState(new Date().getTime());
  const [actionContext, setActionContext] = React.useState();

  const [actionDetails, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'applicationItems.GET_APPLICATION_ITEM',
    !showingId ? undefined : { userContext, objectId: showingId },
    {
      disableEmptyArgs: true,
      reFetchWhen: [refreshKey, showingId],
      onSuccess: (val) => {
        setActionContext(val.item);
        updateChatClient && updateChatClient({ action: val.item });
      },
      onError: (err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      }
    }
  );

  const [locationContext] = useAPIData(
    'context.LOAD_CONTEXT',
    !actionContext?.locationId
      ? undefined
      : { contextId: actionContext?.locationId, bypass: true, audience: AUDIENCES.showing.uuid },
    {
      reFetchWhen: [actionContext?.locationId],
      disableEmptyArgs: true,
      onSuccess: (val) => {
        updateChatClient && updateChatClient({ location: val });
      }
    }
  );

  const steps = ActionSteps.BasicShowingFlow({
    action: actionContext,
    location: locationContext,
    companyContext
  });

  if (actionContext) {
    actionContext.stepStatus =
      actionContext.stepStatus ||
      steps.reduce(
        (final, step) => {
          final.count = final.count + 1;
          final.complete =
            final.complete +
            ([STEP_STATES.done, STEP_STATES.completed].includes(step?.status?.state) ? 1 : 0);
          return final;
        },
        { complete: 0, count: 0 }
      );
  }

  const routes = steps.reduce((final, z) => {
    return final.concat(
      z.routes.map((route) => Object.assign(route, { section: z.key, sectionTitle: z.title }))
    );
  }, []);
  const routeMap = routes.reduce(
    (finalMap, rte) => Object.assign(finalMap, { [rte.path]: rte }),
    {}
  );

  const nextStep = React.useMemo(() => {
    if (!actionContext) return null;
    return steps.filter((s) => !s?.status?.hidden && !s.status?.locked).pop();
  }, [steps]);

  const handleActions = (action) => {
    console.log(action);
    switch (true) {
      case action.id === 'refresh':
        setRefreshKey(new Date().getTime());
        return;
      case action.id === 'save':
        return SAVE_APPLICATION_ITEM({
          userContext,
          objectId: showingId,
          data: {
            step: action.data.type,
            content: action.data.content
          }
        })
          .then(() => {
            toast.success('Saved');
            setRefreshKey(new Date().getTime());
            return true;
          })
          .catch((err) => {
            console.error({ errData: err.data, err });
            toast.error(err.data?.message ? err.data?.message : err.message || err.name);
            return false;
          });
      case action.id === 'payment-coupon':
        return REDEEM_CODE({
          userContext,
          code: action.data,
          data: { applicationId: actionContext._id }
        })
          .then((newData) => {
            if (!newData.status) {
              return toast.error('Missing required data');
            }
            return true;
          })
          .catch((err) => {
            console.error({ errData: err.data, err });
            toast.error(err.data?.message ? err.data?.message : err.message || err.name);
            return false;
          });
      case action.id === 'payment-start':
        return GET_PAYMENT_SESSION({ userContext, applicationId: actionContext._id }).then((z) => {
          window.location = z.paymentUrl;
        });
      case action.id === 'submit':
        if (!action.data?.signature || !action.data?.name) {
          toast.error('Please specify your legal name and sign the agreement to continue.');
          return Promise.resolve(false);
        }
        return urlToFile(action.data.signature, 'signature', 'image/png')
          .then((file) =>
            ADD_FILE({
              userContext,
              locationId: actionContext.locationId,
              applicationId: actionContext._id,
              field: 'signature',
              file: file
            })
          )
          .then(() => {
            //do submit again!
            return SAVE_APPLICATION_ITEM({
              userContext,
              objectId: actionContext._id,
              data: {
                step: 'submit',
                content: { name: action.data.name }
              }
            })
              .then(() => {
                toast.success('Saved');
                setRefreshKey(new Date().getTime());
                return true;
              })
              .catch((err) => {
                console.error({ errData: err.data, err });
                toast.error(err.data?.message ? err.data?.message : err.message || err.name);
                return false;
              });
          })
          .catch((err) => {
            console.error({ errData: err.data, err });
            toast.error(
              `Signature failed: ${err.data?.message ? err.data?.message : err.message || err.name}`
            );
            return false;
          });

      case action.id === 'change-property':
        setShowLocation(true);
        return;
      default:
        return Promise.resolve();
    }
  };

  return {
    onRefresh: () => setRefreshKey(new Date().getTime()),
    handleActions,
    navigate,
    nextStep,
    dataFetched,
    routes,
    routeMap,
    steps,
    action: actionContext,
    location: locationContext
  };
};
