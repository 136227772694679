import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { useAPIData } from 'dal/useAPIData';
import useAudience from 'hooks/useAudience';
import Icon from 'components/icon';
import Property from 'components/property';
import TwoPaneLayout from '../two-pane';

const RightPaneWrapper = styled.div`
  width: 100%;
  border: solid 1px #dfe7ed;
  background-color: #fff;
  overflow-y: auto;

  ${({ isMobile }) => (isMobile ? 'padding: 5px 10px;' : `padding: 28px;`)}
`;

const StyledProperty = styled(Property)`
  width: 100%;
  flex-shrink: 0;
  ${({ isMobile }) => (isMobile ? '' : `max-width:375px;`)}
`;
const StyledLoader = styled(Icon)`
  width: 100%;
  margin: auto;
  flex-shrink: 0;
  ${({ isMobile }) => (isMobile ? '' : `max-width:375px;`)}
`;

const PropertyPanel = ({ mobileMaxWidth, hideWhenNoLocation, locationId, onChangeProperty }) => {
  const [audience] = useAudience();
  const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth });
  const contextId = locationId;
  const [data, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_CONTEXT',
    !contextId ? undefined : { contextId: contextId, audience: audience?.uuid },
    { reFetchWhen: [contextId || ''], disableEmptyArgs: true }
  );

  if (!contextId && hideWhenNoLocation) return <></>;

  if (!dataFetched) {
    return (
      <StyledLoader name="fa-duotone fa-spinner-third fa-spin" size={100} isMobile={isMobile} />
    );
  }

  return (
    <StyledProperty
      location={data || {}}
      forceDisplay={true}
      isMobile={isMobile}
      onChangeProperty={onChangeProperty}
    />
  );
};

export default ({
  children,
  mobileMaxWidth = 800,
  locationId,
  hideWhenNoLocation,
  onChangeProperty,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth });

  return (
    <TwoPaneLayout
      {...props}
      mobileMaxWidth={mobileMaxWidth}
      leftPane={
        <PropertyPanel
          hideWhenNoLocation={hideWhenNoLocation}
          mobileMaxWidth={mobileMaxWidth}
          onChangeProperty={onChangeProperty}
          locationId={locationId}
        />
      }
      rightPane={<RightPaneWrapper isMobile={isMobile}>{children}</RightPaneWrapper>}
    />
  );
};
