import React from 'react';
import styled from 'styled-components';
import { useDocumentContext } from 'e-signature/state';
import Sign from 'e-signature/sign';
import Menu from 'components/menu';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 12px;
  &.signed {
    padding: 2px;
    border: 1px solid #000;
  }
  &.block-edit {
    background-color: #fff175;
    cursor: pointer;
    &:hover {
      background-color: #f1e157;
    }
  }
`;

const SignaturePlaceHolder = styled.div`
  flex: 1;
  height: 100%;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  image-rendering: auto;
`;

export default ({ block, updateBlock, mode }) => {
  const { ui } = useDocumentContext();
  const tempSignature = block.value && ui.signature?.initialsImage;
  const image = mode === 'view' ? block.value : tempSignature;
  const [createSignature, setCreateSignature] = React.useState();

  const handleSign = () => {
    if (!ui.signature) setCreateSignature(true);
    updateBlock({ uuid: block.uuid, value: true });
  };

  const handleOptions = ({ id }) => {
    if (id === 'change') return setCreateSignature(true);
    updateBlock({ uuid: block.uuid, value: undefined });
  };

  return (
    <>
      {mode === 'view' && (
        <Wrapper className="signed">
          <SignaturePlaceHolder url={image} />
        </Wrapper>
      )}
      {mode === 'edit' && (
        <>
          {image && (
            <Menu
              options={[
                { label: 'Change...', id: 'change' },
                { label: 'Clear', id: 'clear' }
              ]}
              onItemClick={handleOptions}>
              {(provided) => (
                <Wrapper {...provided} className="signed block-edit">
                  <SignaturePlaceHolder url={image} />
                </Wrapper>
              )}
            </Menu>
          )}
          {!image && (
            <Wrapper className="block-edit" onClick={handleSign}>
              Initials here
            </Wrapper>
          )}
          {createSignature && (
            <Sign
              onCancel={() => setCreateSignature(false)}
              onSave={() => {
                updateBlock({ uuid: block.uuid, signed: true });
                setCreateSignature(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
