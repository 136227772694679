import styled from 'styled-components';
import React from 'react';
import Icon from 'components/icon';

const Wrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const BackNext = styled.button`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #f5f5f5;
  box-shadow: none;
  border: none;
  fot-size: 12px;
  color: #444;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;

export default ({ blocks, setFocusedBlock }) => {
  return (
    <Wrapper>
      <BackNext onClick={() => setFocusedBlock('prev')}>
        <Icon name="fa-solid fa-arrow-left" />
      </BackNext>
      Required Fields {blocks.filter((b) => b.isRequired && b.isCompleted).length}/
      {blocks.filter((b) => b.isRequired).length}
      <BackNext onClick={() => setFocusedBlock('next')}>
        <Icon name="fa-solid fa-arrow-right" />
      </BackNext>
    </Wrapper>
  );
};
