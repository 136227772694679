import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { CREATE_APPLICATION } from '../../dal/applications';
import { getContinueUrl } from 'helpers/appVersions';
import { UserContext, useNavigateWithContext } from '../../state';
import { Row, Title, PageContainer, PageWrapper } from '../application-form-v2/common-styles';
import FormGroup, { RowGroup } from 'components/form-group';
import Button from 'components/button';
import Input from 'components/input';
import Phone from 'components/phone';
import { useFormik } from 'formik';
import * as yup from 'yup';

export default ({ location }) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigateWithContext();
  const { userContext } = React.useContext(UserContext);
  const initialValues = {
    email: userContext?.email || '',
    phone: [{ number: userContext?.phone || '', type: 'mobile' }],
    firstName: userContext?.firstName || '',
    lastName: userContext?.lastName || '',
    middleName: userContext?.middleName
  };

  const handleContinue = (values) => {
    setSaveInProgress(true);

    if (saveInProgress) {
      return;
    }

    setSaveInProgress(true);
    CREATE_APPLICATION({
      userContext,
      locationId: location._id,
      data: {
        applicant: values,
        moveIn: '',
        hasToured: undefined,
        tourDate: undefined,
        locationId: location._id,
        companyId: params.orgId,
        version: 'v2',
        step: 'verify-identity'
      }
    })
      .then((newData) => {
        setSaveInProgress(false);
        if (newData?.application) {
          navigate(getContinueUrl(newData.application));
        }
      })
      .catch((err) => {
        setSaveInProgress(false);
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };

  const applicantInfoSchema = yup.object().shape({
    firstName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`),
    lastName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.lastName')}`),
    middleName: yup.string(),
    email: yup
      .string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    phone: yup
      .array(
        yup.object({
          number: yup
            .string()
            .phone(`${t('form.validate.invalidPhone')}`)
            .required(`${t('form.validate.phone')}`),
          type: yup.string()
        })
      )
      .required(`${t('form.validate.phone')}`)
  });

  const [saveInProgress, setSaveInProgress] = React.useState(false);

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: applicantInfoSchema,
      onSubmit: handleContinue
    });

  return !open ? null : (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('yourInfo.title')}</Title>
        </Row>
        <Row spacing={30}>
          <RowGroup columns="1fr 1fr">
            <FormGroup
              label={t('form.legalFirstName')}
              errorText={touched.firstName && errors.firstName}>
              <Input
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label={t('form.legalMiddleName')}
              errorText={touched.middleName && errors.middleName}>
              <Input
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </RowGroup>
          <RowGroup columns="1fr ">
            <FormGroup
              label={t('form.legalLastName')}
              errorText={touched.lastName && errors.lastName}>
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </RowGroup>
          <FormGroup label={t('form.phone')}>
            <Phone
              errors={errors.phone}
              name="phone"
              value={values.phone}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup label={t('form.email')} errorText={touched.email && errors.email}>
            <Input
              icon="fa-regular fa-at"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        </Row>
        <Row className="center">
          <Button
            radius="25px"
            variant="primary"
            disabled={saveInProgress}
            onClick={handleSubmit}
            width={95}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
