import React from 'react';
import styled from 'styled-components';
import { useDocumentContext } from './state';
//import { format } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

const getTimeStamp = (date) =>
  date &&
  format(utcToZonedTime(new Date(date), 'UTC'), 'MM/dd/yyyy HH:mm:ss  z', {
    timeZone: 'UTC'
  });

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  padding: 30px;
  overflow-y: auto;
  .item {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px 10px;
  &.header {
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }
  image {
    width: 100px;
  }
`;

const Table = styled.div`
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TableBody = ({ items = [], doc, isCompanyList }) => (
  <div>
    {items.map((r) => (
      <div key={r.uuid} className="item">
        <Row>
          <div className="">
            <div>
              <strong>{r.name}</strong>
            </div>
            <div>{r.email}</div>
          </div>
          <div />
          <div>
            <img width="100px" src={r.signature?.url} />
          </div>
        </Row>
        <Row>
          <div className="">
            <div>Sent</div>
            <div>Last View</div>
            <div>Signed</div>
          </div>
          <div>
            <div>{getTimeStamp(doc.createdAt) || '-'}</div>
            <div>{getTimeStamp(r.lastSeen) || '-'}</div>
            <div>{getTimeStamp(r.signature?.signedAt) || '-'}</div>
          </div>
          <div>
            <div>IP Address: {r.signature?.ip}</div>
            <div>{r?.signature?.location?.formattedAddress}</div>
          </div>
        </Row>
      </div>
    ))}
  </div>
);

export default () => {
  const { doc } = useDocumentContext();
  const counterSigners = doc.recipients
    .filter((r) => r.role === 'counterSigner')
    .concat(doc.companySigners || []);

  return (
    <Wrapper>
      <h2>Signatures</h2>
      <h3>ACCEPTANCE OF LEASE</h3>
      <p>
        By signing here, you agree that the signature and any initials in the document will be the
        electronic representation of your signature and initials for all purposes, including legally
        binding contracts - just the same as a pen-and-paper signature or initial.
      </p>
      <Table>
        <Row className="header">
          <div>Recipients</div>
          <div>Timestamp</div>
          <div>Signature</div>
        </Row>
        <TableBody items={doc.recipients.filter((r) => r.role === 'signer')} doc={doc} />
      </Table>
      <Table>
        <Row className="header">
          <div>Company signer</div>
          <div>Timestamp</div>
          <div>Signature</div>
        </Row>
        <TableBody items={counterSigners} doc={doc} />
      </Table>
    </Wrapper>
  );
};
