import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  i {
    background-color: #fff;
  }
  &.mode-print {
  }
  &.mode-edit {
    cursor: pointer;
    i {
      background-color: #fff175;
    }
  }
`;

export default ({ block, mode, updateBlock }) => {
  return (
    <Wrapper className={`mode-${mode}`}>
      <i
        className={
          block.value === true
            ? 'fa-sharp fa-regular fa-square-xmark'
            : 'fa-sharp fa-regular fa-square'
        }
        onClick={() => {
          if (mode !== 'edit') return;
          updateBlock({ uuid: block.uuid, value: block.value === true ? undefined : true });
        }}
      />
    </Wrapper>
  );
};
