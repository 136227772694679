import { ellipsis } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  &.mode-print,
  &.mode-build,
  &.mode-view {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }
  &.mode-build {
    background-color: ${({ bg }) => bg};
    div {
      ${ellipsis()}
    }
  }
`;

export default ({ block, mode }) => {
  return (
    <Wrapper className={`mode-${mode}`} bg={block.backgroundColor} fontSize={block.fontSize}>
      {['view', 'build', 'edit', 'print'].includes(mode) && (
        <div dangerouslySetInnerHTML={{ __html: block.value }} />
      )}
    </Wrapper>
  );
};
