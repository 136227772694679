import React, { useState, Suspense } from 'react';
import 'normalize.css';
import Screening from './pages';
import AptlyForms from './aptly-forms';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AppUiStateProvider } from 'state';
import EB from 'components/errorBoundary';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';
import ConfirmDialog from 'components/confirmation-dialog';
require('./utils/dateHelpers');
require('./utils/numberHelpers');
require('./utils/stringHelpers');
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import Loading from './components/loading';
import ESignature from './e-signature';

// eslint-disable-next-line no-undef
const STATIC_CONTENT_URL = `${process.env.REACT_APP_STATIC_CONTENT_URL}`;
// eslint-disable-next-line no-undef
const ROLLBAR_ACTIVE = !!`${process.env.REACT_APP_ROLLBAR_KEY}`;
const ROLLBAR_CONFIG = {
  // eslint-disable-next-line no-undef
  accessToken: `${process.env.REACT_APP_ROLLBAR_KEY}`,
  // eslint-disable-next-line no-undef
  environment: `${process.env.REACT_APP_ROLLBAR_ENV}`,
  enabled: ROLLBAR_ACTIVE,
  captureIp: 'anonymize',
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: {
    log: false
  }
};

const rollbar = new Rollbar(ROLLBAR_CONFIG);

const AppContainer = () => {
  const [locale, setLocale] = useState(i18n.language);
  React.useEffect(() => {
    i18n.on('languageChanged', (lng) => setLocale(i18n.language));
  }, []);
  return (
    <AppUiStateProvider>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Routes>
              <Route path="document/:documentId/:token" element={<ESignature />} />
              <Route path="form/:formId/:cardId/:boardId/:action" element={<AptlyForms />} />
              <Route path="form/:formId/:cardId/:boardId" element={<AptlyForms />} />
              <Route path="*" element={<Screening />} />
            </Routes>
            <Toaster position="bottom-right" />
            <ConfirmDialog />
          </BrowserRouter>
        </Suspense>
      </LocaleContext.Provider>
    </AppUiStateProvider>
  );
};

function App() {
  return ROLLBAR_ACTIVE ? (
    <Provider instance={rollbar}>
      <ErrorBoundary>
        <EB>
          <AppContainer />
        </EB>
      </ErrorBoundary>
    </Provider>
  ) : (
    <AppContainer />
  );
}

export default App;
