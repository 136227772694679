import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import Icon from 'components/icon';

import { Row, Title, PageWrapper, PageContainer } from '../common-styles';
import VerifastModal from './verifast-modal';
import ManualVerification from './manual-verification';

const Step = styled.div`
  padding: 10px 0px;
  border-radius: 6px;
  .title {
    display: flex;
    align-items: end;
    gap: 5px;
  }
  li {
    list-style: none;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 740px) {
    padding: 0 10px;
  }
`;

const RowLogos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: 20px;
  img {
    width: 60px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
  .box-persona {
    position: relative;

    img {
      width: 120px;
      margin-top: -25px;
      margin-left: -10px;
    }
    .title {
      position: absolute;
      margin-top: -10px;
      font-size: 0.63em;
    }
    .privacy {
      position: absolute;
      font-size: 0.69em;
      margin-top: -30px;
      a {
        color: #007cff;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .box-persona {
      flex-basis: 100%;
      text-align: center;
      > div {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
const StyledLoader = styled(Icon)`
  width: 100%;
  margin: auto;
  flex-shrink: 0;
`;

export default ({ action, location, handleActions, onBack, onClose }) => {
  const disableAuto = !!location?.showingConfig?.disableAutoIDVerification;
  const requireAuto =
    !!location?.showingConfig?.requireAutoIDVerification &&
    !location?.showingConfig?.disableAutoIDVerification;

  const [openModal, setOpenModal] = React.useState(false);
  const { t } = useTranslation();
  const [openNoIdModal, setOpenNoIdModal] = React.useState(disableAuto);
  const showManualStep = disableAuto || openNoIdModal;
  const onRefresh = () => handleActions({ id: 'refresh' });
  const onNext = () => {
    // onSubmit(vals);
    onClose();
  };

  return (
    <PageWrapper>
      <PageContainer>
        {showManualStep && !requireAuto ? (
          <ManualVerification
            t={t}
            autoIdDisabled={disableAuto}
            open={openNoIdModal}
            onClose={() => (disableAuto ? onBack() : setOpenNoIdModal(false))}
            action={action}
            onNext={() => {
              onRefresh();
              onNext();
            }}
          />
        ) : (
          <>
            <VerifastModal
              actionId={action?._id}
              open={openModal}
              requestType="identity"
              onClose={() => {
                setOpenModal(false);
              }}
              onComplete={() => {
                setOpenModal(false);
                onRefresh();
                onNext();
              }}
            />
            <Row className="center">
              <Title>{t('verifyYourIdentity.title')}</Title>
            </Row>
            <Row className="center">
              <Icon name="fa-duotone fa-face-viewfinder" color="#00aade" size={60} />
            </Row>
            <div className="description-box">
              <Row spacing={0}>
                <p>{t('verifyYourIdentity.description')}</p>
                <p>{t('verifyYourIdentity.description-2')}</p>
                <Step>
                  <p className="title">
                    <Icon name="fa-duotone fa-circle-1" size={25} />
                    {t('verifyYourIdentity.stepPhoto.title')}
                  </p>
                  <ul>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepPhoto.descrip-1')}
                    </li>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepPhoto.descrip-2')}
                    </li>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepPhoto.descrip-3')}
                    </li>
                  </ul>
                </Step>
                <Step>
                  <p className="title">
                    <Icon name="fa-duotone fa-circle-2" size={25} />
                    {t('verifyYourIdentity.stepSelfie.title')}
                  </p>
                  <ul>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepSelfie.descrip-1')}
                    </li>
                  </ul>
                </Step>
              </Row>
            </div>
            <Row className="flex gap-10 center">
              <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
                {t('buttonsActions.cancel')}
              </Button>
              <Button radius="25px" variant="primary" onClick={() => setOpenModal(true)} width={90}>
                {t('verifyYourIdentity.actions.primary')}
              </Button>
            </Row>
            {!location?.showingConfig?.requireAutoIDVerification && (
              <Row className="center">
                <Button
                  className="skip-verify"
                  radius="25px"
                  variant="link"
                  onClick={() => setOpenNoIdModal(true)}>
                  {t('verifyYourIdentity.actions.secondary')}
                </Button>
              </Row>
            )}
            <br />
            <Row className="center" spacing={0}>
              <Row spacing={0}>
                <RowLogos>
                  <div className="box-persona">
                    <div className="title">POWERED BY</div>
                    <img className="img-persona" src="/assets/bitmap.jpg" alt="" />
                    <div className="privacy">
                      Persona&apos;s{' '}
                      <span>
                        <a
                          href="https://www.personasupport.org/privacy-policy"
                          target="_blank"
                          rel="noreferrer">
                          privacy policy
                        </a>
                      </span>
                    </div>
                  </div>
                  <div>
                    <img src="/assets/ccpa.png" alt="" />
                  </div>
                  <div>
                    <img src="/assets/gdpr.png" alt="" />
                  </div>
                  <div>
                    <img src="/assets/soc2.png" alt="" />
                  </div>
                  <div>
                    <img src="/assets/fr.png" alt="" />
                  </div>
                </RowLogos>
              </Row>
            </Row>
          </>
        )}
      </PageContainer>
    </PageWrapper>
  );
};
