import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import escapeStringRegexp from 'helpers/escape-regex';
import { CompanyContext, UserContext, useNavigateWithContext } from 'state';
import PendingLocation from 'components/pending-location';
import Icon from 'components/icon';
import { useAPIData } from 'dal/useAPIData';
import useAudience from 'hooks/useAudience';
import { useContextId, useOrgId } from 'hooks/useSessionVariables';

import Location from './location';
import Filters from './filters';
import EmptyImg from './empty.gif';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  background: #fff;
  .backIcon {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 16px;
    cursor: pointer;
  }
`;

const Header = styled.div`
  padding: 20px 40px 20px;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0;
`;

const Grid = styled.div`
  display: grid;
  padding: 0 40px;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding-bottom: 20px;
  @media (min-width: 3200px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 3200px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 2900px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 2600px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 2400px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
  }
  .selected {
    border: 1px solid #c5e4ed;
    padding: 5px;
    background-color: #c5e4ed85;
  }
`;
const MoreLocationsWrapper = styled.div`
  min-width: 290px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  > div {
    padding: 20px;
    border: 1px solid #c5e4ed;
    padding: 5px;
    background-color: #c5e4ed85;
  }
`;

const NoFountBox = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
  i {
    display: block;
    font-size: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  img {
    width: 300px !important;
  }
  .text-error {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default ({
  onBack,
  onSelect,
  onPreview,
  hideHeader,
  className,
  hideDefaultLocation,
  selectContent
}) => {
  const [audience] = useAudience();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const { companyContext } = React.useContext(CompanyContext);
  const { userContext } = React.useContext(UserContext);
  const orgId = useOrgId();
  const contextId = useContextId();
  const navigate = useNavigateWithContext();
  const { t } = useTranslation();

  const onSelectWrapper = (location, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (location?.isLocked) {
      return setModalOpen(true);
    }
    onSelect ? onSelect(location) : navigate(`app/location-list/${location._id}`);
  };

  const [locationList = [], fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_LOCATIONS',
    {
      userContext,
      orgId,
      filters: filters
        ? {
            ...filters,
            audience: audience?.uuid,
            minBath: '' // TODO: bugged data causes this to often times be string and not work on the server side filters
          }
        : filters
    },
    {
      disableEmptyArgs: true,
      reFetchWhen: [filters],
      wait: 300
    }
  );
  const [defaultLocation] = useAPIData(
    'context.LOAD_CONTEXT',
    hideDefaultLocation || !contextId
      ? undefined
      : { contextId: contextId, audience: audience?.uuid },
    { reFetchWhen: [contextId], disableEmptyArgs: true }
  );

  //filter
  const [list, setList] = React.useState([]);
  const [selectedPrice, setSelectedPrice] = React.useState({ min: 0, max: 15000 });

  const handleChangePrice = (min, max) => {
    setSelectedPrice({ min, max });
  };

  const { otherLocations, generalLocations, locationCount } = React.useMemo(() => {
    return {
      otherLocations: (!contextId ? locationList : locationList.filter((c) => c._id !== contextId))
        .filter((g) => !g.applicationConfig?.general)
        .slice(0, 50)
        .sort((a, b) =>
          a?.name?.localeCompare(b?.name || '', 'en', {
            sensitivity: 'base'
          })
        ),
      locationCount: (!contextId
        ? locationList
        : locationList.filter((c) => c._id !== contextId)
      ).filter((g) => !g.applicationConfig?.general).length,
      generalLocations:
        (!contextId ? locationList : locationList.filter((c) => c._id !== contextId))
          .filter((g) => g.applicationConfig?.general)
          .sort((a, b) =>
            a?.name?.localeCompare(b?.name || '', 'en', {
              sensitivity: 'base'
            })
          ) || []
    };
  }, [locationList?.length]);

  const applyFilters = () => {
    let updatedList = otherLocations;

    // Search Filter
    if (filters?.address) {
      updatedList = updatedList.filter(
        (item) =>
          item?.address?.formattedAddress
            ?.toLowerCase()
            ?.search(escapeStringRegexp(filters.address.toLowerCase().trim())) !== -1
      );
    }
    if (filters?.minBed) {
      updatedList = updatedList.filter(
        (item) => item?.bedCount && Number(item.bedCount) >= Number(filters?.minBed)
      );
    }
    if (filters?.minBath) {
      updatedList = updatedList.filter(
        (item) => item?.bathCount && Number(item.bathCount) >= Number(filters?.minBath)
      );
    }

    // Price Filter
    const minPrice = selectedPrice?.min;
    const maxPrice = selectedPrice?.max;

    updatedList = updatedList.filter(
      (item) =>
        (item?.applicationConfig?.marketRentValue || 0) / 100 >= Number(minPrice) &&
        (item?.applicationConfig?.marketRentValue || 0) / 100 <= Number(maxPrice)
    );

    setList(updatedList);
  };
  useEffect(() => {
    applyFilters();
  }, [filters, selectedPrice, locationList?.length]);

  const showMoreLocations = otherLocations?.length < locationCount;
  return (
    <Wrapper className={className}>
      {onBack && <Icon onClick={onBack} name="fa-solid fa-x backIcon" />}
      {!hideHeader && (
        <Header>
          <Title>{t('locationList.title')}</Title>
          <div>{t('locationList.description')}</div>
        </Header>
      )}
      {modalOpen && (
        <PendingLocation open={modalOpen} onClose={() => setModalOpen(false)} location={location} />
      )}
      <Filters
        filters={filters}
        setFilters={setFilters}
        selectedPrice={selectedPrice}
        changePrice={handleChangePrice}
      />
      {list.length + generalLocations.length === 0 ? (
        <NoFountBox>
          {dataFetched ? (
            <>
              <img
                src={EmptyImg}
                style={{ width: '400px', margin: '0 auto' }}
                alt="empty-location"
              />
              <div className="text-error">{t('noResultsFound')}</div>
            </>
          ) : (
            <Icon name="fa-duotone fa-spinner-third fa-spin" />
          )}
        </NoFountBox>
      ) : (
        <>
          <Grid className="locationsGrid">
            {defaultLocation && (
              <Location
                className="selected"
                location={defaultLocation}
                onPreview={onPreview}
                onClick={(e) => onSelectWrapper(defaultLocation, e)}
                selectContent={selectContent}
                companyContext={companyContext}
              />
            )}
            {list?.map((location) => (
              <Location
                key={location._id}
                location={location}
                onPreview={onPreview}
                onClick={(e) => onSelectWrapper(location, e)}
                selectContent={selectContent}
                companyContext={companyContext}
              />
            ))}
            {generalLocations?.map((location) => (
              <Location
                key={location._id}
                location={location}
                onPreview={onPreview}
                onClick={(e) => onSelectWrapper(location, e)}
                selectContent={selectContent}
                companyContext={companyContext}
              />
            ))}

            {showMoreLocations && (
              <MoreLocationsWrapper>
                <div>
                  + {locationList?.length - otherLocations?.length}{' '}
                  {t('locationList.moreLocations.otherPlaces')}
                  <br />
                  {t('locationList.moreLocations.useFiltersFind')}
                </div>
              </MoreLocationsWrapper>
            )}
          </Grid>
        </>
      )}
    </Wrapper>
  );
};
