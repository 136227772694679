import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import Button from 'components/button';
import CancelDialog from './cancel-showing-dialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const Content = styled.div``;

const Title = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 1.15em;
  text-align: center;
  margin-bottom: 10px;
`;

const Logo = styled.div`
  width: 100%;
  height: 280px;
  border-radius: 2px;
  border: none;
  background-image: url(${({ image }) => image}), url('/assets/location_placeholder.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const DataRow = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 80px;
  > div {
    display: flex;
    flex-flow: column;
    > span {
      color: #71828f;
      text-transform: uppercase;
      font-size: 0.9em;
    }
    > div {
      font-size: 1.2em;
    }
  }
  .unitInfo {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    gap: 3px;
    font-size: 1em;
    > div {
      font-size: 1em;
    }
  }
`;

const TourStatus = styled.div`
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  > span {
    color: #71828f;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  > div {
    font-size: 1.2em;
    text-transform: uppercase;
    &.pending {
      color: #ff8200;
    }
    &.incomplete {
      color: #00a1ff;
    }
    &.accepted {
      color: #4aac00;
    }
    &.denied,
    &.cancelled {
      color: #d20000;
    }
  }
  > p {
    margin: 5px 0 0;
    padding: 0;
    color: #71828f;
    font-size: 0.8em;
  }
`;

const ActionRow = styled.div`
  margin: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex-grow: 1;
  }

  button {
    padding: 5px 10px !important;
    font-size: 0.81em;
    width: auto;

    &.primary {
      font-weight: 900;
      text-transform: uppercase;
      border-radius: 25px;
      color: #00a1ff;
      border: solid 1px #00a1ff;
      background-color: #fff;
      &:hover {
        background-color: #00a1ff;
        color: #fff;
      }
    }
    &.secondary {
      text-transform: uppercase;
      font-weight: 900;
      border-radius: 25px;
      border: solid 1px #d20000;
      color: #d20000;
      background-color: #fff;
      &:hover {
        background-color: #d20000;
        color: #fff;
      }
    }
    &.primaryFocused {
      font-weight: 900;
      text-transform: uppercase;
      border-radius: 25px;
      color: #fff;
      border: solid 1px #00a1ff;
      background-color: #00a1ff;
      &:hover {
        background-color: #fff;
        color: #00a1ff;
      }
    }
  }
`;

const STATUS_ENUM = {
  incomplete: 'INCOMPLETE',
  pending: 'REQUESTED',
  accepted: 'ACCEPTED',
  denied: 'DENIED',
  canceled: 'CANCELLED'
};

export default ({ showing, navigate, refreshList }) => {
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const location = showing?.location || {};
  const image =
    location?.applicationConfig?.coverPhoto?.[0] ||
    location?.applicationConfig?.marketingImages?.[0];

  const cancelAction = () => setShowCancelDialog(true);
  const continueAction = () => navigate(`app/showing/${showing._id}/summary`);

  let status = showing.submitted ? 'pending' : 'incomplete';
  if (showing.status) {
    status = showing.status.isApproved ? 'accepted' : 'denied';
  }

  return (
    <Wrapper>
      {showCancelDialog && (
        <CancelDialog
          showing={showing}
          onClose={({ cancelled } = {}) => {
            setShowCancelDialog(false);
            if (cancelled) {
              refreshList();
            }
          }}
        />
      )}

      <Content>
        <Title>{location.address?.formattedAddress || location.name}</Title>
        <Logo image={image} />
        <DataRow>
          {!!location?.applicationConfig?.marketRentValue && (
            <div>
              <span>
                <Trans>Rent</Trans>
              </span>
              <div>
                <strong>
                  {(location?.applicationConfig?.marketRentValue / 100).renderPrettyCurrency(false)}
                </strong>
                /mo
              </div>
            </div>
          )}
          {!!location.applicationConfig?.availableDate && (
            <div>
              <span>
                <Trans>Available</Trans>
              </span>
              <div>
                <strong>{location.applicationConfig?.availableDate}</strong>
              </div>
            </div>
          )}
          {(!!location.bathCount || !!location.bedCount || !!location?.squareFeet) && (
            <div className="unitInfo">
              {(!!location.bedCount || !!location.bathCount) && (
                <div>
                  {location.bedCount ? `${location.bedCount} BD` : ''}
                  {location.bedCount && location.bathCount ? ` | ` : ''}
                  {location.bathCount ? `${location.bathCount} BA` : ''}
                </div>
              )}
              {!!location?.squareFeet && <div>{location.squareFeet} SF</div>}
            </div>
          )}
        </DataRow>
        <TourStatus>
          <span>
            <Trans>Tour Status</Trans>
          </span>
          <div className={status}>
            <strong>{STATUS_ENUM[status]}</strong>
          </div>
          <p>
            Requested: {showing.createdAt?.toLocaleDateString()} at{' '}
            {showing.createdAt?.toLocaleTimeString()}
          </p>
        </TourStatus>
      </Content>
      <ActionRow>
        {/* {!!showing.status?.isApproved && (
          <Button variant="primaryFocused" onClick={continueAction}>
            <Trans i18nKey="showing.actions.accessCode">Access Code</Trans>
          </Button>
        )} */}
        <div>
          {showing.submitted ? (
            <>
              <Button variant="primary" onClick={continueAction}>
                <Trans i18nKey="showing.actions.update">Update</Trans>
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={continueAction}>
              <Trans i18nKey="showing.actions.completeRequest">Continue</Trans>
            </Button>
          )}
          <Button variant="secondary" onClick={cancelAction} width={80}>
            <Trans i18nKey="showing.actions.cancel">Cancel</Trans>
          </Button>
        </div>
      </ActionRow>
    </Wrapper>
  );
};
