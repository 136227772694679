import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  textarea {
    height: 100%;
    width: 100%;
    resize: none;
    background-color: #fff;
    border: 1px solid #000;
    background-color: #fff175;
    box-shadow: none;
    margin: 0;
    font-size: ${({ fontSize = 14 }) => fontSize}px;
  }
`;

export default ({ block, mode, updateBlock }) => {
  return (
    <Wrapper className={`mode-${mode}`} fontSize={block.fontSize}>
      {mode === 'edit' && (
        <textarea
          placeholder="Type something"
          value={block.value || ''}
          onChange={(e) => {
            if (mode !== 'edit') return;
            updateBlock({ uuid: block.uuid, value: e.target.value });
          }}
        />
      )}
      {mode === 'view' && <div>{block.value}</div>}
    </Wrapper>
  );
};
