import React from 'react';
import styled from 'styled-components';
import html2React from 'html-react-parser';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import capitalize from 'lodash/capitalize';

import FormFieldFactory from 'components/form-field-factory';
import FormGroup from 'components/form-group';
import SignaturePad from 'components/signature-pad';
import Button from 'components/button';
import LoadingScreen from 'components/full-screen-loader';
import FeedBackScreen from 'components/feedback-screen';
import { getValidationSchemaForField } from '../utils/field-validations';
import { GET_FORM_DATA, SUBMIT_FORM_DATA } from '../dal/forms';
import ThankYouPage from './thankyou-page';

function loadScript(url, success) {
  var script = document.createElement('script');
  script.src = url;
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
}

const Wrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background-color: ${({ bg = '#fff' }) => bg};
  display: flex;
  flex-direction: column;
  .placeholder-footer {
    height: 158px;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .u-row {
    margin: 0 auto;
  }
`;

const ControlWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  input,
  textarea,
  select {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    &:focus {
      border-color: #000;
    }
  }
`;

const Submit = styled(Button)`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000 !important;
`;

const Actions = styled.div`
  box-sizing: border-box;
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 270px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 50px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  .captchaTest {
    display: none;
  }
  &.captcha-visible {
    .captchaTest {
      display: block;
    }
  }
  .captchaLabel {
    margin-bottom: 7px;
  }
`;

const complexErrorToString = (error, depth = 0, includeKeyName) => {
  if (!error || isString(error) || depth > 3) return error;
  if (Array.isArray(error)) {
    return error
      .map((err) => complexErrorToString(err, depth + 1))
      .filter((z) => z)
      .join(', ');
  }
  if (isObject(error)) {
    return Object.keys(error)
      .map(
        (z) =>
          `${includeKeyName ? `${capitalize(z)}: ` : ''}${complexErrorToString(
            error[z],
            depth + 1
          )}`
      )
      .join(', ');
  }

  return 'Please provide data';
};

const Form = ({
  subject,
  fields = [],
  body = '',
  signature,
  hasBodySignature,
  initialValues: formInitialValues = {},
  boardId,
  cardId,
  formId,
  propertyId,
  onSuccess,
  onPaymentRequired,
  onError,
  pageBg,
  setPageBg,
  enableCaptcha,
  paymentRequired,
  paymentAmount
}) => {
  const captchaWidget = React.useRef(null);
  const [captchaToken, setToken] = React.useState();
  const [showCaptcha, setShowCaptcha] = React.useState(false);

  const { initialValues, fieldsMap, validationSchema } = React.useMemo(() => {
    const fieldsMap = {};
    const initialValues = {};
    const formSchema = {};

    if (signature || hasBodySignature) {
      formSchema.signature = yup.string().required('Please add your signature!');
    }

    fields.forEach((field) => {
      fieldsMap[field.uuid] = field;
      initialValues[field.uuid] = formInitialValues[field.uuid];
      formSchema[field.uuid] = getValidationSchemaForField(field);
    });

    const validationSchema = yup.object().shape(formSchema);

    return { fieldsMap, initialValues, validationSchema };
  }, []);

  const submitForm = async (values) => {
    try {
      const data = await SUBMIT_FORM_DATA({
        data: {
          ...values,
          aid: cardId,
          bid: boardId,
          fid: formId,
          propertyId,
          captcha: captchaToken
        }
      });
      if (data.status === 'success') {
        onSuccess?.();
      } else if (data.status === 'payment-required') {
        onPaymentRequired?.(data);
      } else {
        onError?.();
      }
    } catch (error) {
      onError?.(error);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    validateForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (enableCaptcha && !captchaToken) return setShowCaptcha(true);
      setSubmitting(true);
      submitForm(values);
    }
  });
  React.useEffect(() => {
    document.title = subject;
    const a = document.getElementsByClassName('u_body');
    const bg = a?.[0]?.style?.backgroundColor;
    bg && setPageBg(bg);
  }, [subject]);

  const elements = html2React(body, {
    replace: (domNode) => {
      if (domNode?.attribs?.['data-form-control']) {
        const uuid = domNode?.attribs?.['data-field-uuid'];
        const field = fieldsMap[uuid];
        return (
          <ControlWrapper>
            <FormGroup errorText={touched[uuid] && complexErrorToString(errors[uuid], 0, false)}>
              <FormFieldFactory
                name={uuid}
                value={values[uuid]}
                field={field}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </ControlWrapper>
        );
      }
    }
  });

  React.useEffect(() => {
    if (enableCaptcha) {
      window.portalFormsGrecaptcha = function () {
        captchaWidget.current = grecaptcha.render('portal_forms_grecaptcha', {
          sitekey: '6LcjyQAVAAAAANsQu1Ory3Q2mz2Y3fMwjKVFN8no',
          callback: (token) => {
            setToken(token);
            handleSubmit();
          }
        });
      };
      loadScript(
        'https://www.google.com/recaptcha/api.js?onload=portalFormsGrecaptcha&render=explicit'
      );
    }
  }, []);

  return (
    <>
      <FormWrapper bg={pageBg}>
        <div>{elements}</div>
        {!hasBodySignature && signature && (
          <Actions>
            <div>
              <FormGroup label="Signature" errorText={errors.signature}>
                <SignaturePad name="signature" value={values.signature} onChange={handleChange} />
              </FormGroup>
            </div>
          </Actions>
        )}
      </FormWrapper>
      <SubmitWrapper className={showCaptcha ? 'captcha-visible' : ''}>
        {enableCaptcha && (
          <>
            <div className="captchaTest">
              <div className="captchaLabel">Please resolve the captcha</div>
              <div id="portal_forms_grecaptcha" />
            </div>
          </>
        )}

        <Actions>
          {paymentRequired && (
            <Submit
              type="submit"
              variant="primary"
              disabled={isSubmitting ? true : showCaptcha}
              onClick={handleSubmit}>
              {isSubmitting ? 'PAYING...' : `PAY ${paymentAmount}`}
            </Submit>
          )}
          {!paymentRequired && (
            <Submit
              type="submit"
              variant="primary"
              disabled={isSubmitting ? true : showCaptcha}
              onClick={handleSubmit}>
              {isSubmitting ? 'SENDING...' : 'SUBMIT'}
            </Submit>
          )}
        </Actions>
      </SubmitWrapper>
    </>
  );
};

export default () => {
  const [data, setData] = React.useState();
  const [error, setError] = React.useState(false);
  const [formSubmited, setSubmited] = React.useState(false);
  const { boardId, cardId, formId, action } = useParams();
  const [pageBg, setPageBg] = React.useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const propertyId = queryParameters.get('uid');

  const getForm = async () => {
    try {
      const data = await GET_FORM_DATA({
        cardId,
        boardId,
        formId
      });

      data.propertyId = propertyId;
      if (!data.boardId) setError(true);
      setData(data);
    } catch (error) {
      setError(true);
    }
  };

  React.useEffect(() => {
    getForm();
  }, []);

  if (error) return <FeedBackScreen variant="error" text="Opps!! Something went wrong 😔" />;
  if (!data) return <LoadingScreen text="LOADING FORM" />;
  if (formSubmited || ['pay-success', 'success'].includes(action)) {
    return data.thankYouPage ? (
      <ThankYouPage html={data.thankYouPage} />
    ) : (
      <FeedBackScreen variant="success" text="Thank you, your data has been received!" />
    );
  }
  if (action === 'pay-cancel') {
    return (
      <FeedBackScreen
        variant="error"
        text={
          <div style={{ padding: '0 20px' }}>
            <h3>You've cancelled your transaction!</h3>
            <div>Please re-submit your information and complete the required payment.</div>
            <div>
              <a href={location.href.replace('pay-cancel', '')}>Continue</a>
            </div>
          </div>
        }
      />
    );
  }

  return (
    <Wrapper bg={pageBg}>
      <Form
        {...data}
        onSuccess={() => setSubmited(true)}
        onPaymentRequired={(data) => {
          window.location = data.paymentUrl;
        }}
        onError={() => setError(true)}
        pageBg={pageBg}
        setPageBg={setPageBg}
      />
      {/* <div className="placeholder-footer" /> */}
    </Wrapper>
  );
};
