import React from 'react';
import styled from 'styled-components';
import InputPhone from 'components/input-phone';
import { validatePhoneNumber } from 'utils/phoneHelpers';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 5px;
  position: relative;
  input {
    height: 100%;
    width: 100%;
    border: 1px solid #000;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
    font-size: ${({ fontSize = 14 }) => fontSize}px;
    background-color: #fff175;
  }
  &.mode-print,
  &.mode-build,
  &.mode-view {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .invalidPhone {
    outline-color: red;
    background-color: #ffe2e2;
  }
`;

const Input = ({ value, onChange }) => {
  const [phone, setPhone] = React.useState(value);
  const isValid = validatePhoneNumber(phone || '');
  const [focused, setFocused] = React.useState();
  return (
    <>
      <InputPhone
        onFocus={() => setFocused(true)}
        className={!isValid && focused ? 'invalidPhone' : ''}
        tag="defaultInput"
        value={phone}
        onChange={(e) => {
          const val = e.target.value;
          const isValid = validatePhoneNumber(e.target.value);
          onChange(isValid ? val : '');
          setPhone(val);
        }}
        type="text"
      />
    </>
  );
};

export default ({ block, mode, updateBlock }) => {
  return (
    <Wrapper className={`mode-${mode}`} fontSize={block.fontSize}>
      {mode === 'edit' && (
        <Input
          value={block.value}
          onChange={(phone) => {
            if (mode !== 'edit') return;
            updateBlock({ uuid: block.uuid, value: phone });
          }}
        />
      )}
      {mode === 'view' && block.value && <div>{block.value}</div>}
    </Wrapper>
  );
};
