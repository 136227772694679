import React from 'react';
import styled from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Input from './input';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { format, set } from 'date-fns';

const FORMAT = 'MM/dd/yyyy';
const currentYear = new Date().getFullYear();
const fromMonth = new Date(1940, 0);
const toMonth = new Date(currentYear + 10, 11);

const parseDate = (str, format, locale) => {
  if (!str) return undefined;
  const isNotValid = str.split('/').pop().length < 4;
  if (isNotValid) return undefined;
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) return parsed;
  return undefined;
};

const formatDate = (date, format, locale) => {
  return dateFnsFormat(date, format, { locale });
};

const MonthYearWrapper = styled.form`
  min-width: 240px;
  select {
    border-radius: 4px;
    -webkit-appearance: none;
    height: 30px;
    padding: 0 10px;
    background-color: var(--color-bg-primary);
    border: none;
    &:first-child {
      margin-right: 2px;
    }
  }
`;

const YearMonthForm = ({ date, localeUtils, onChange, onBlur }) => {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <MonthYearWrapper className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </MonthYearWrapper>
  );
};

const ScrollIntoView = ({ timeoutLength = 500, datePickerRef }) => {
  const getPageSize = () =>
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const scrollIt = () =>
    datePickerRef?.current?.input?.scrollIntoView &&
    datePickerRef?.current?.input?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });

  const currentWindowSize = getPageSize();
  scrollIt();

  // timeout handles the appearance of keyboards...
  setTimeout(() => {
    const newWindowSize = getPageSize(); //we check height so we don't re-scroll
    if (newWindowSize !== currentWindowSize) scrollIt();
  }, timeoutLength);
};

const InputDate = ({ value, name, onChange, disabledDays, autoScroll = true, ...props }) => {
  const datePickerRef = React.useRef(null);
  const [month, setMonth] = React.useState('');

  return (
    <DayPickerInput
      ref={datePickerRef}
      value={value ? new Date(value) : undefined}
      parseDate={parseDate}
      format={FORMAT}
      formatDate={formatDate}
      placeholder="MM/DD/YYYY"
      component={Input}
      onDayChange={(date) => {
        if (date?.setHours) {
          date.setHours(10, 0, 0, 0, 0); //set to middle of day to get around timezone issues
        }
        onChange && onChange({ target: { name, value: date } });
      }}
      keepFocus={false}
      onDayPickerShow={() => autoScroll && ScrollIntoView({ datePickerRef })}
      dayPickerProps={{
        month,
        disabledDays: disabledDays,
        captionElement: ({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            localeUtils={localeUtils}
            onChange={(month) => setMonth(month)}
          />
        )
      }}
      {...props}
    />
  );
};

const DateTimeWrapper = styled.div`
  display: flex;
  gap: 10px;
  .input-time {
    padding: 0 10px;
  }
  & > div:first-child {
    flex: 1;
  }
`;

export const InputDateTime = ({ name, value, onChange, onBlur }) => {
  const [selectedDay, setSelectedDay] = React.useState(value ? new Date(value) : undefined);
  const [time, setTime] = React.useState(value ? format(new Date(value), 'HH:mm') : '');

  const handleSelect = (event) => {
    const day = event.target.value;
    setSelectedDay(day);
  };

  const handleTimeChange = (e) => {
    const { value } = e.target;
    setTime(value);
  };

  React.useEffect(() => {
    if (!selectedDay || !time) return;
    const hours = Number.parseInt(time.split(':')[0] || '00', 10);
    const minutes = Number.parseInt(time.split(':')[1] || '00', 10);
    const date = set(selectedDay, { hours, minutes });
    onChange && onChange({ target: { name, value: date } });
  }, [selectedDay, time]);

  return (
    <DateTimeWrapper>
      <InputDate value={selectedDay} onChange={handleSelect} onBlur={onBlur} />
      <input
        className="input-time"
        type="time"
        value={time}
        onChange={handleTimeChange}
        onBlur={onBlur}
      />
    </DateTimeWrapper>
  );
};

export default InputDate;
