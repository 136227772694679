import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AUDIENCES } from 'helpers/enums';
import useAudience from 'hooks/useAudience';
import useMobile from 'hooks/useMobile';
import CreateAccount from './create-account';
import UserLogin from './user-login';
import useActions from './useActions';
import LoginChallenge from './login-challenge';

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 28px;
  border: solid 1px #dfe7ed;
  background-color: #fff;
  overflow: auto;

  .title {
    display: flex;
    justify-content: center;
    font-size: 2em;
    font-weight: 900;
    color: #0d2a36;
    margin-bottom: 20px;
  }
  .sub-title {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 900;
    color: #0d2a36;
    margin-bottom: 20px;
  }
  .description {
    font-size: 1em;
    color: #0d2a36;
    margin-bottom: 40px;
  }
  .footer {
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    font-size: 1em;
    color: #0d2a36;
    a {
      text-decoration: none;
      font-weight: bold;
      color: #00a1ff;
    }
  }
  &.mobile-login {
    .login-form {
      gap: 5px !important;
      > div {
        margin-bottom: 5px !important;
      }
    }
    .sub-title {
      display: none;
    }
    .title {
      margin-bottom: 10px;
    }
    .description {
      margin-bottom: 20px;
    }
  }
`;

const AUDIENCE_CONFIGS = {
  [AUDIENCES.showing.uuid]: () => {
    return {
      title: <Trans i18nKey="login.showing.title">Schedule Your Tour</Trans>,
      description: (
        <Trans i18nKey="login.showing.description">
          Register for a self-tour with just a few easy steps. To self-tour our homes you will need
          a smart phone with bluetooth and GPS capabilities. Location services must be turned on in
          your smart phone settings. If you do not have access to a smart phone, we recommend
          scheduling a tour with one of our leasing agents.
        </Trans>
      )
    };
  },
  [AUDIENCES.approver.uuid]: () => {
    return {
      title: <Trans i18nKey="login.approver.title">Review your shared information</Trans>,
      description: (
        <Trans i18nKey="login.approver.description">
          View the information shared with you in just a few easy steps.
        </Trans>
      )
    };
  },
  default: () => {
    return {
      title: (
        <Trans i18nKey="login.applicant.title">Your new home is just a few clicks away!</Trans>
      ),
      description: (
        <Trans i18nKey="login.applicant.description">
          Complete your application with just a few easy steps.
        </Trans>
      )
    };
  }
};

export default () => {
  const { isTabletOrMobile } = useMobile();
  const { loginView } = useParams();
  const { handleActions, view, registerData } = useActions({ loginView });
  const [audience] = useAudience();
  const content = (AUDIENCE_CONFIGS[audience.uuid] || AUDIENCE_CONFIGS.default)();

  if (view === 'loginChallenge') {
    return (
      <Wrapper>
        <LoginChallenge handleActions={handleActions} />
      </Wrapper>
    );
  }

  return (
    <Wrapper className={isTabletOrMobile ? 'mobile-login' : ''}>
      {view === 'login' && <UserLogin handleActions={handleActions} content={content} />}
      {view === 'signup' && <CreateAccount handleActions={handleActions} content={content} />}
      {view === 'ssoRegister' && (
        <CreateAccount
          handleActions={handleActions}
          content={content}
          registerData={registerData}
          isSSO={true}
        />
      )}
      <div className="footer">
        <div>
          <Trans i18nKey="poweredBy">Powered by APTLY</Trans>
        </div>
        <div>
          By proceeding you agree to the{' '}
          <a href="https://www.getaptly.com/terms/" target="_blank" rel="noreferrer">
            Aptly Terms of Use
          </a>{' '}
          and{' '}
          <a href="https://www.getaptly.com/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </Wrapper>
  );
};
