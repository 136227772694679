import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Input from 'components/input';
import Relationship from 'components/relationship';
import FormGroup from 'components/form-group';
import Text from 'components/text';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import InputDate from 'components/input-date';
import { useFormik } from 'formik';
import { randomId } from 'dal/utils';
import Phone from 'components/phone';
import MultiUpload from '../multi-upload';
import { omit } from 'lodash';

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
const memberSchema = (t) => {
  return {
    type: yup.string(),
    uuid: yup.string(),
    firstName: yup.string().required(`${t('form.validate.name')}`),
    middleName: yup.string(),
    lastName: yup.string().required(`${t('form.validate.lastName')}`),
    email: yup
      .string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    phone: yup
      .array(
        yup.object({
          number: yup
            .string()
            .phone(`${t('form.validate.invalidPhone')}`)
            .required(`${t('form.validate.phoneNumber')}`),
          uuid: yup.string(),
          type: yup.string()
        })
      )
      .min(1, `${t('form.validate.phone')}`)
      .required(`${t('form.validate.phone')}`),
    message: yup.string().max(1000, `${t('form.validate.messageLong')}`),
    relationship: yup.string().required(`${t('form.validate.relationship')}`),
    pastRoommate: yup.boolean(),
    dateOfBirth: yup
      .date()
      .required(`${t('form.validate.birthDate')}`)
      .test('dateOfBirth', `${t('form.validate.birthDate')}`, (value) => {
        return new Date().getTime() >= new Date(value).getTime();
      })
  };
};

const MAX_FILE_COUNT = 3;
const defaultMember = {
  firstName: '',
  lastName: '',
  relationship: '',
  email: '',
  phone: [{ number: '', type: 'mobile' }],
  pastRoommate: false,
  message: '',
  dateOfBirth: ''
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 30px 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 56px;
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 1.13em;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  justify-content: flex-end;
  gap: 12px;
  button {
    padding: 7px 12px !important;
    text-transform: uppercase;
  }
`;

const fieldsToOmit = {
  minor: ['phone', 'email', 'message', 'pastRoommate', 'relationship'],
  emergencyContact: ['message', 'pastRoommate', 'dateOfBirth'],
  default: ['dateOfBirth'],
  guarantor: ['pastRoommate', 'dateOfBirth']
};

const NewMember = ({ onClose, member: initialMember, onSave, location, application }) => {
  const { t } = useTranslation();

  const marriageCertificateRequired = !!location?.companyInfo?.requireMarriageCertificate;
  const [partnerFiles, setPartnerFiles] = React.useState(
    application?.fileRefs?.filter(
      (fr) =>
        initialMember?.uuid &&
        fr.referenceId === initialMember?.uuid &&
        fr.type === 'marriageCertificate'
    ) || []
  );

  const { initialValues, validationSchema } = React.useMemo(() => {
    const member = { uuid: randomId(), ...defaultMember, ...initialMember };
    const omittedFields = fieldsToOmit[initialMember.type] || fieldsToOmit.default;
    const initialValues = omit(member, omittedFields);
    const schema = omit(memberSchema(t), omittedFields);

    if (initialMember?.type === 'minor' && !location?.companyInfo?.requireMinorDOB) {
      delete schema.dateOfBirth;
    }
    if (!schema.dateOfBirth) delete initialValues.dateOfBirth;

    return { initialValues, validationSchema: yup.object().shape(schema) };
  }, [initialMember]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    validate: (submittedValues) => {
      const errors = {};
      if (
        marriageCertificateRequired &&
        submittedValues?.relationship === 'partner' &&
        !partnerFiles?.length
      ) {
        errors.marriageCertificate = t('form.validate.marriageCertificate');
      }

      return errors;
    },
    onSubmit: (values) => {
      onSave({ uuid: randomId(), ...values });
      onClose();
    }
  });

  const LABELS = {
    TITLE: {
      minor: `${t('newMembers.minor')}`,
      resident: `${t('newMembers.resident')}`,
      partner: `${t('newMembers.partner')}`,
      guarantor: `${t('newMembers.guarantor')}`,
      emergencyContact: `${t('newMembers.emergencyContact')}`
    }
  };

  return (
    <Wrapper>
      <Header>{values.uuid ? `Edit` : LABELS.TITLE[values.type]}</Header>
      <Scrollable>
        {!!validationSchema?.fields?.email && (
          <FormGroup label={t('form.email')} errorText={touched.email && errors.email}>
            <Input
              icon="fa-solid fa-at"
              name="email"
              value={values.email}
              onChange={(e) => {
                e.target.value = (e.target.value || '').trim(); // force whitespace trim
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
          </FormGroup>
        )}
        <FormGroup label={t('form.firstName')} errorText={touched.firstName && errors.firstName}>
          <Input
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup label={t('form.middleName')} errorText={touched.middleName && errors.middleName}>
          <Input
            name="middleName"
            value={values.middleName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup label={t('form.lastName')} errorText={touched.lastName && errors.lastName}>
          <Input
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        {!!validationSchema?.fields?.relationship && (
          <FormGroup
            label={t('form.relationship')}
            errorText={touched.relationship && errors.relationship}>
            <Relationship
              name={`relationship`}
              value={values.relationship}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        )}
        {marriageCertificateRequired && values.relationship === 'partner' && (
          <FormGroup
            label={t('form.marriageCertificate')}
            title={t('form.marriageCertificateTooltip')}
            errorText={errors.marriageCertificate}>
            <MultiUpload
              onChange={setPartnerFiles}
              application={application}
              field="marriageCertificate"
              referenceId={values.uuid}
              maxFiles={MAX_FILE_COUNT}
              onUploadComplete={() => {
                setFieldTouched('marriageCertificate', true); // set touched to force a re-validation
              }}
              fileTypes={['.pdf', '.jpg', '.jpeg', '.png']}
            />
          </FormGroup>
        )}
        {!!validationSchema?.fields?.dateOfBirth && location?.companyInfo?.requireMinorDOB && (
          <FormGroup
            label={t('form.birthDate')}
            errorText={touched.dateOfBirth && errors.dateOfBirth}>
            <InputDate
              icon="fa-solid fa-calendar"
              name="dateOfBirth"
              disabledDays={(date) => date > new Date()}
              value={values.dateOfBirth}
              onChange={(e) => {
                e.target.value = e.target.value || '';
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
          </FormGroup>
        )}
        {!!validationSchema?.fields?.phone && (
          <>
            <FormGroup label={t('form.phone')}>
              <Phone
                errors={touched.phone && errors.phone}
                name="phone"
                value={values.phone}
                onChange={handleChange}
              />
            </FormGroup>
          </>
        )}
        {!!validationSchema?.fields?.message && (
          <FormGroup label={t('form.message')} errorText={touched.message && errors.message}>
            <Text
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        )}
        {!!validationSchema?.fields?.pastRoommate && (
          <FormGroup>
            <Checkbox
              label={t('form.weLivedCheckbox')}
              checked={values.pastRoommate}
              onChange={() => setFieldValue('pastRoommate', !values.pastRoommate)}
            />
          </FormGroup>
        )}
      </Scrollable>
      <Actions>
        <Button variant="secondary" onClick={onClose}>
          {t('buttonsActions.cancel')}
        </Button>
        <Button variant="primary" radius="25px" onClick={handleSubmit}>
          {values.uuid
            ? `${t('buttonsActions.save')}`
            : !['minor', 'emergencyContact'].includes(values.type)
            ? `${t('buttonsActions.sendInvite')}`
            : `${t('ADD')}`}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default ({ open, onClose, ...props }) => (
  <Modal closeOnOutsideClick={false} open={open} onClose={onClose} width="600px" height="90%">
    <NewMember onClose={onClose} {...props} />
  </Modal>
);
