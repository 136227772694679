import React from 'react';
import styled from 'styled-components';
import { Row, Title, PageContainer, PageWrapper } from './common-styles';
import FormGroup, { RowGroup } from 'components/form-group';
import Button from 'components/button';
import Input from 'components/input';
import Phone from 'components/phone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UserContext } from 'state';
import { useTranslation } from 'react-i18next';

export default ({ application, saveApplicationPart, onNext, onBack }) => {
  const { t } = useTranslation();
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const { userContext } = React.useContext(UserContext);
  const initialValues = {
    email: userContext?.email || '',
    phone: [{ number: userContext?.phone || '', type: 'mobile' }],
    firstName: userContext?.firstName || '',
    lastName: userContext?.lastName || '',
    ...(application.applicant || {})
  };

  const applicantInfoSchema = yup.object().shape({
    firstName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`),
    lastName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.lastName')}`),
    middleName: yup.string(),
    email: yup
      .string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    phone: yup
      .array(
        yup.object({
          number: yup
            .string()
            .phone(`${t('form.validate.invalidPhone')}`)
            .required(`${t('form.validate.phone')}`),
          type: yup.string()
        })
      )
      .required(`${t('form.validate.phone')}`)
  });

  const handleContinue = (values) => {
    setSaveInProgress(true);
    saveApplicationPart({
      applicant: values,
      moveIn: values.moveIn,
      hasToured: values.hasToured,
      tourDate: values.tourDate
    })
      .then(() => {
        onNext();
        setSaveInProgress(false);
      })
      .catch(() => setSaveInProgress(false));
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema: applicantInfoSchema,
    onSubmit: handleContinue
  });

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('yourInfo.title')}</Title>
        </Row>
        <Row spacing={30}>
          <Row>
            <RowGroup columns="1fr 1fr">
              <FormGroup
                label={t('form.legalFirstName')}
                errorText={touched.firstName && errors.firstName}>
                <Input
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <FormGroup
                label={t('form.legalMiddleName')}
                errorText={touched.middleName && errors.middleName}>
                <Input
                  name="middleName"
                  value={values.middleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
            </RowGroup>
            <FormGroup
              label={t('form.legalLastName')}
              errorText={touched.lastName && errors.lastName}>
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup label={t('form.phone')}>
              <Phone
                errors={errors.phone}
                name="phone"
                value={values.phone}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup label={t('form.email')} errorText={touched.email && errors.email}>
              <Input
                icon="fa-regular fa-at"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Row>
        </Row>
        <Row className=" flex gap-10 center">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button
            radius="25px"
            variant="primary"
            disabled={saveInProgress}
            onClick={handleSubmit}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
