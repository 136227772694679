import React from 'react';
import styled from 'styled-components';
import ShadowRender from 'components/shadow-render';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  all: initial;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1.2;
  word-break: break-word;
  p {
    margin: 0;
  }
  & > ul {
    padding-left: 1.5em !important;
    list-style-type: disc !important;
    list-style-position: inside;
  }
`;

export default ({ block }) => {
  return (
    <Wrapper className={block.value ? '' : 'empty-block'}>
      <Content>
        <ShadowRender html={block.value} />
      </Content>
    </Wrapper>
  );
};
