import React from 'react';
import styled, { css } from 'styled-components';

import Modal, { ModalScrollable } from 'components/modal';
import Icon from 'components/icon';

const CloseIcon = styled(Icon)`
  position: absolute;

  ${({ isMobile }) =>
    isMobile
      ? css`
          top: 5px;
          right: 5px;
        `
      : css`
          top: 15px;
          right: 15px;
        `}
`;
const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccccccab;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UnknownStep = () => <div>Unknown Step</div>;
const LoadingPane = () => (
  <Loading>
    <Icon name="fa-duotone fa-spinner-third fa-spin" size={96} />
  </Loading>
);

const ComponentModal = ({
  modalType,
  data,
  location,
  steps,
  handleActions,
  isMobile,
  onClose,
  onDone
}) => {
  const [loading, setLoading] = React.useState(false);
  const matchingStep = steps.find((step) => step.key === modalType);
  const UXElement = matchingStep?.routes?.[0]?.Component;

  if (!UXElement) return <></>;

  return (
    <Modal closeOnOutsideClick={true} open={true} width="800px" height="auto">
      <ModalScrollable>
        <CloseIcon name="fa-solid fa-xmark" isMobile={isMobile} onClick={onClose} size={24} />
        {loading && <LoadingPane />}
        <UXElement
          location={location}
          action={data}
          application={data}
          loading={loading}
          isMobile={isMobile}
          onDone={onDone}
          onBack={onClose}
          onClose={onClose}
          setLoading={setLoading}
          handleActions={handleActions}
          onSubmit={(values, options) => {
            !options?.hideLoader && setLoading(true);
            return handleActions({
              id: 'save',
              data: {
                content: matchingStep.encode ? matchingStep.encode(values) : values,
                type: !options?.stepIncomplete ? modalType : undefined
              }
            })
              .then((result) => {
                !options?.hideLoader && setLoading(false);
                //if failed to save, don't continue
                if (result && !options?.keepOpen) onClose?.();
                return result;
              })
              .catch((err) => {
                console.error(err);
                !options?.hideLoader && setLoading(false);
                return false;
              });
          }}
        />
      </ModalScrollable>
    </Modal>
  );
};

export default ({ open = false, ...props }) => {
  return !open ? null : <ComponentModal {...props} />;
};
