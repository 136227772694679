import React from 'react';
import 'normalize.css';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { useRollbar } from '@rollbar/react';
import 'helpers/yup';
import { useAPIData } from 'dal/useAPIData';
import { GET_CACHED_USER, UPDATE_USER } from 'dal/user';
import { UserContext, CompanyContext, useNavigateWithContext } from 'state';
import { setMultipleKeys } from 'hooks/useSessionStorage';
import { useIntercom } from 'components/plugins/intercom';
import { useTokenExpirationWarning } from './snacks';
import Routes from './routes';

const Screening = () => {
  const rollbar = useRollbar();
  const navigate = useNavigateWithContext();
  const [companyContext, setCompanyContext] = React.useState();
  const [orgId, setOrgId] = React.useState();
  const [userContext, setUserContext] = React.useState();
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [reLoginToken, setReLoginToken] = React.useState(new Date().getTime());

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const parts = window.location.pathname.split('/').filter((z) => z);
    const cId = ['locations', 'listing'].includes(parts[parts.length - 2])
      ? parts[parts.length - 1]
      : undefined;

    setMultipleKeys({
      orgId: parts[0],
      contextId: cId,
      refCode: queryParams.get('ref'),
      resetCode: queryParams.get('resetCode')
    });
    setOrgId(parts[0]);
  }, []);

  // load the cached user and if session still active then refresh the access token.
  React.useEffect(() => {
    GET_CACHED_USER()
      .then((user) => {
        setUserContext(user);
        setUserLoaded(true);
      })
      .catch(() => {
        setUserLoaded(true);
      });
  }, [reLoginToken]);

  const [data, inProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_ORG_CONTEXT',
    !orgId ? undefined : { contextId: orgId },
    {
      reFetchWhen: [orgId],
      disableEmptyArgs: true,
      onSuccess: (val) => {
        setCompanyContext(val);
      }
    }
  );

  React.useEffect(() => {
    if (companyContext?.companyName) {
      document.title = `${companyContext?.companyName} Screening`;
    }
  }, [companyContext?.companyName]);

  const bounceUser = React.useCallback(
    (msg) => {
      const lastPath = window.location.pathname;
      navigate(
        `home/login?${
          msg && msg?.trim
            ? `msg=${encodeURIComponent('Your session has expired. Please login again.')}&`
            : ''
        }redir=${encodeURIComponent(lastPath)}`
      );
    },
    [userContext?._id, orgId]
  );

  const updateUserContext = React.useCallback(
    (params) => {
      UPDATE_USER({ ...params, userContext }).then((user) => {
        toast.success(`${t('userUpdate')}`);
        if (user) {
          setUserContext(user);
        }
      });
    },
    [userContext?._id, orgId]
  );

  // Warn if session is expiring...
  useTokenExpirationWarning({
    userContext,
    setUserContext,
    bounceUser,
    refetchToken: () => setReLoginToken(new Date().getTime())
  });

  //set user context
  React.useEffect(() => {
    if (userContext?._id) {
      rollbar.configure({
        orgId,
        person: { id: userContext._id, email: userContext.email, username: userContext.email },
        payload: {
          person: { id: userContext._id, email: userContext.email, username: userContext.email }
        }
      });
    }
    //}, [window.location.pathname]);
  }, [userContext?._id, orgId]);

  const [chatReady, updateChatClient, toggleChatWindow] = useIntercom({
    userContext,
    companyContext
  });

  if ((orgId && !failedFetch && !dataFetched) || !userLoaded) {
    return <div>Loading....</div>;
  }

  return (
    <CompanyContext.Provider value={{ companyContext, setCompanyContext, toggleChatWindow }}>
      <UserContext.Provider value={{ userContext, setUserContext, bounceUser, updateUserContext }}>
        <Routes updateChatClient={updateChatClient} />
      </UserContext.Provider>
    </CompanyContext.Provider>
  );
};

export default Screening;
