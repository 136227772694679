import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Button from 'components/button';
import Icon from 'components/icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
  }
`;

const NavButton = styled(Button)`
  color: #fff;
  position: absolute;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
`;

const Close = styled(Button)`
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  i {
    margin-right: 10px;
  }
`;

const LabelCount = styled.div`
  width: 100px;
  position: absolute;
  bottom: 15px;
  left: calc(50% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const Viewer = ({ urls = [], onClose }) => {
  const [position, setPosition] = React.useState(0);
  const currentPos = `${position + 1}/${urls.length}`;
  const currentImage = urls[position];
  return (
    <Wrapper>
      <img src={currentImage} alt="" />
      <LabelCount>{currentPos}</LabelCount>
      {position > 0 && (
        <NavButton className="left" onClick={() => setPosition(position - 1)}>
          <Icon name="fa-solid fa-chevron-left" />
        </NavButton>
      )}
      {position + 1 < urls.length && (
        <NavButton className="right" onClick={() => setPosition(position + 1)}>
          <Icon name="fa-solid fa-chevron-right" />
        </NavButton>
      )}
      <Close onClick={onClose}>
        <Icon name="fa-light fa-xmark" />
        CLOSE
      </Close>
    </Wrapper>
  );
};

export default ({ open, ...props }) => {
  return (
    <Modal width="100vw" height="100vh" maxHeight="100vw" radius="0px" color="#000" open={open}>
      <Viewer {...props} />
    </Modal>
  );
};
