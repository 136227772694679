import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import Button from 'components/button';
import { useGoogle } from 'dal/sso/google';

export const LoginButton = styled(Button)`
  cursor: pointer;
  display: flex;
  height: 40px !important;
  border-radius: 25px !important;
  gap: 10px;
  align-items: center;
  min-width: 200px;
  padding: 15px;
  text-transform: uppercase;
  justify-content: center;
  &.primary {
    background-color: #00a1ff;
    font-weight: 900;
    color: #fff;
  }
  &.secondary {
    border: solid 1px #00a1ff;
    color: #00a1ff;
    background-color: #fff;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  gap: 20px;
  .button-row {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    > div,
    > button {
      flex: 1;
      width: 100%;
      min-height: 40px;
      height: 40px;
    }

    &.login {
      @media (min-width: 401px) {
        flex-wrap: nowrap;
        flex-flow: row;
        > button {
          flex: 1;
        }
        > div {
          flex: 1;
        }
      }
    }
  }
  .forgot-password {
    align-self: flex-end;
  }
  #googleSignInParentElement,
  #googleSignIn {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
`;

const GoogleSSOButton = React.memo(({ handleActions }) => {
  const [buttonSetup, setButtonSetup] = React.useState(false);
  const [isReady, setupButton] = useGoogle({
    onLogin: (response) => {
      handleActions({ id: 'ssoLogin', data: response });
    },
    onRegister: (response) => {
      handleActions({ id: 'ssoRegister', data: response });
    }
  });

  const createButton = () => {
    if (buttonSetup) return;

    setButtonSetup(
      setupButton({
        buttonId: 'googleSignIn',
        signUp: false,
        parentElementId: 'googleSignInParentElement'
      })
    );
  };

  React.useEffect(() => createButton, [isReady]);

  return (
    <div id="googleSignInParentElement">
      <div id="googleSignIn" />
    </div>
  );
});

export const LoginActions = ({
  state,
  handleActions,
  onCreateAccount,
  onLogin,
  onForgotPassword
}) => {
  const CreateAccount = () => (
    <div className="button-row">
      <LoginButton
        className={'primary'}
        onClick={onCreateAccount || (() => handleActions({ id: 'setView', data: 'signup' }))}>
        Create Account
      </LoginButton>
    </div>
  );

  return (
    <ButtonWrapper>
      {state === 'login' ? (
        <Button
          className="forgot-password"
          variant="link"
          onClick={onForgotPassword || (() => handleActions({ id: 'forgotPassword' }))}>
          <Trans i18nKey="homePage.forgotPassword">Forgot your password?</Trans>
        </Button>
      ) : (
        <CreateAccount />
      )}
      <div className="button-row login">
        <LoginButton
          className={'secondary'}
          onClick={onLogin || (() => handleActions({ id: 'setView', data: 'login' }))}>
          Login
        </LoginButton>
        <GoogleSSOButton handleActions={handleActions} />
      </div>
      {state !== 'login' ? <></> : <CreateAccount />}
    </ButtonWrapper>
  );
};
