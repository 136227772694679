import React from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { UserContext, useNavigateWithContext, CompanyContext } from 'state';
import Modal from 'components/modal';
import Icon from 'components/icon';
import FontSelect from 'components/font-select';
import { LOGOUT } from 'dal/user';
import useAudience from 'hooks/useAudience';
import { AUDIENCES } from 'helpers/enums';

const Item = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 15px;
  padding: 0 20px;
  color: #0d2a36;
  background-color: #fff;
  &:hover {
    color: #000;
  }
`;

const Wrapper = styled.div`
  min-width: 240px;
  background-color: #0d2a36;
  height: 100%;
  position: relative;

  .spacer{
    height:8px; background-color: #fff;
  }
  .closeButton {
    position: absolute;
    top:10px;
    right 10px;
    cursor:pointer;
    font-size:14px;
  }
  .sub-item-hidden {
    display: none;
  }
  .account {
    color: #fff;
    background-color: #0d2a36;
    &:hover {
      background-color: #0d3647;
    }
  }
  .sub-item {
    padding-left: 40px;
    .sub-item-hidden {
      display: inline-block;
    }
  }
`;

const MenuList = styled.div``;

const MENU_OPTIONS = ({ t, companyContext }) => {
  let options = [
    {
      id: 'menuShares',
      icon: 'fa-duotone fa-rectangle-history-circle-user',
      label: `${t('sidebar.shares')}`,
      path: 'shares/list',
      authReq: true,
      audience: AUDIENCES.approver.uuid,
      audiences: [AUDIENCES.approver.uuid]
    },
    // {
    //   id: 'menuHome',
    //   icon: 'fa-duotone fa-house-chimney-heart',
    //   label: `${t('sidebar.home')}`,
    //   path: 'app/home',
    //   authReq: true,
    //   audiences: [AUDIENCES.applicant.uuid, AUDIENCES.showing.uuid]
    // },
    {
      id: 'menuTours',
      icon: 'fa-duotone fa-key',
      label: `${t('sidebar.tours')}`,
      path: 'app/showing',
      audience: AUDIENCES.showing.uuid,
      authReq: true,
      audiences: [AUDIENCES.applicant.uuid, AUDIENCES.showing.uuid]
    },
    {
      id: 'menuNewTour',
      icon: 'fa-plus fa-solid',
      className: 'sub-item',
      label: `${t('sidebar.newTour')}`,
      path: 'app/showing/create',
      audience: AUDIENCES.showing.uuid,
      authReq: true,
      audiences: [AUDIENCES.applicant.uuid, AUDIENCES.showing.uuid]
    },
    {
      id: 'menuApplications',
      icon: 'fa-duotone fa-file-user',
      label: `${t('sidebar.applications')}`,
      path: 'app/applications',
      audience: AUDIENCES.applicant.uuid,
      authReq: true,
      audiences: [AUDIENCES.applicant.uuid, AUDIENCES.showing.uuid]
    },
    {
      id: 'menuCreate',
      icon: 'fa-plus fa-solid',
      className: 'sub-item',
      label: `${t('sidebar.create')}`,
      path: 'app/applications/form/create',
      audience: AUDIENCES.applicant.uuid,
      authReq: true,
      audiences: [AUDIENCES.applicant.uuid, AUDIENCES.showing.uuid]
    },
    {
      id: 'menuSupport',
      icon: 'fa-duotone fa-circle-question',
      className: 'account',
      label: `${t('sidebar.support')}`,
      path: 'support'
    },
    {
      id: 'menuProfile',
      className: 'account',
      icon: 'fa-duotone fa-circle-user',
      label: `${t('sidebar.profile')}`,
      path: 'profile',
      authReq: true
    },
    {
      id: 'menuTextSize',
      icon: 'fa-duotone fa-text-size',
      label: `${t('sidebar.textSize')}`,
      className: 'account',
      modal: 'textSize',
      authReq: false
    },
    {
      id: 'menuLogout',
      icon: 'fa-duotone fa-right-from-bracket',
      className: 'account',
      label: `${t('sidebar.logout')}`,
      path: 'logout',
      authReq: true
    }
  ];

  if (!companyContext?.enableShowings || !companyContext?.showingActive) {
    options = options.filter((opt) => !['menuHome', 'menuTours', 'menuNewTour'].includes(opt.id));
  }

  return options;
};

const MenuItem = ({ icon, label, onClick, className }) => (
  <Item onClick={onClick} className={className}>
    <Icon name={icon} size={'1.13em'} />
    {label}
  </Item>
);

export default ({ anchorEl, anchorDirection, onClose, open }) => {
  const [audience] = useAudience();
  const navigate = useNavigateWithContext();
  const { companyContext } = React.useContext(CompanyContext);
  const { userContext, setUserContext } = React.useContext(UserContext);
  const [activeModal, setActiveModal] = React.useState();
  const { t } = useTranslation();

  const handleClick = (item, e) => {
    if (item.modal) {
      setActiveModal({ modal: item.modal, anchorEl: e.target });
      return;
    }

    onClose?.();

    if (item.path === 'logout') {
      LOGOUT({ userContext })
        .then(() => {
          setUserContext({});
          navigate('home/login');
        })
        .catch((err) => {
          console.error({ errData: err.data, err });
          toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        });
      return;
    }
    navigate(item.path, item.audience);
  };

  return (
    <>
      <Modal
        width={'auto'}
        height={'auto'}
        open={open}
        anchorDirection={anchorDirection}
        anchorEl={anchorEl}
        hideClose={true}
        onClose={onClose}>
        <Wrapper>
          {onClose && <Icon name="fa-solid fa-x closeButton" onClick={onClose} />}
          <div className="spacer" />
          <MenuList>
            {MENU_OPTIONS({ t, companyContext })
              .filter((z) => {
                const audiences = (z.audiences || []).filter((a) => a);
                return !audiences?.length ? true : audiences.includes(audience?.uuid);
              })
              .map((option) => (
                <MenuItem
                  key={option.path || option.id}
                  {...option}
                  onClick={(e) => handleClick(option, e)}
                />
              ))}
          </MenuList>
        </Wrapper>
      </Modal>
      {activeModal?.modal === 'textSize' && (
        <FontSelect
          open={true}
          anchorEl={activeModal?.anchorEl}
          onChange={() => {
            setActiveModal('');
            onClose?.();
          }}
          onClose={() => {
            setActiveModal('');
            onClose?.();
          }}
        />
      )}
    </>
  );
};
