import React from 'react';
import styled from 'styled-components';
import DropdownModal from 'components/dropdown-modal';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .editPreview {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 1px solid #000 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    box-sizing: border-box;
    padding: 0 !important;
    margin: 0;
    font-size: ${({ fontSize = 14 }) => fontSize}px;
    background-color: #fff175 !important;
    font-weight: normal;
  }
  &.mode-view {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;

export default ({ block, mode, updateBlock }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState();
  const val = block.value ? [].concat(block.value) : [];
  const displayVal = val.join(', ');

  return (
    <Wrapper
      className={`mode-${mode}`}
      fontSize={block.fontSize}
      onClick={() => {
        if (!mode === 'edit') return;
        setDropdownOpen(true);
      }}>
      {mode === 'edit' && (
        <div className="editPreview">{val.length === 0 ? 'Select...' : displayVal}</div>
      )}
      {mode === 'view' && <div>{displayVal}</div>}
      {dropdownOpen && mode === 'edit' && (
        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}>
          <DropdownModal
            value={block.value}
            onChange={(opts) => updateBlock({ uuid: block.uuid, value: opts })}
            onClose={() => setDropdownOpen(false)}
            multi={block.dropdownMulti}
            options={block.dropdownOptions}
          />
        </div>
      )}
    </Wrapper>
  );
};
