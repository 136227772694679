import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import differenceInYears from 'date-fns/differenceInYears';

import Button from 'components/button';
import FormGroup, { RowGroup } from 'components/form-group';
import Input from 'components/input';
import InputDate from 'components/input-date';
import Select from 'components/select';
import { showAlternativeCreditOptions } from 'utils/applicationHelper';

import { Row, Title, PageContainer, PageWrapper, Description } from './common-styles';
import { CompanyContext } from '../../state';

const schema = (t, translations, displayAltCreditOptions) => {
  return yup.object({
    firstName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`),
    lastName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.lastName')}`),
    showSSN: yup.boolean(),
    showDOB: yup.boolean(),
    DOB: yup.date().when('showDOB', {
      is: true,
      then: yup
        .date()
        .required(`${t('form.validate.provideDOB')}`)
        .test('DOB', `${t('form.validate.DOB')}`, (value) => {
          return differenceInYears(new Date(), new Date(value)) >= 18;
        }),
      otherwise: (s) => s.strip()
    }),
    SSN: yup.string().when('showSSN', {
      is: true,
      then: yup
        .string()
        .matches(/^((\d{9})|(\d{3}-\d{2}-\d{4}))$/g, translations.SSN)
        .required(translations.SSNrequired),
      otherwise: (s) => s.strip()
    }),
    SSNRepeat: yup.string().when('showSSN', {
      is: true,
      then: yup
        .string()
        .oneOf([yup.ref('SSN'), null], translations.SSNMatch)
        .required(translations.SSNrequired),
      otherwise: (s) => s.strip()
    }),
    consented: yup
      .boolean()
      .oneOf([true], `${t('consentStep.validate.pleaseProvider')}`)
      .required(`${t('consentStep.validate.pleaseProvider')}`),
    ...(displayAltCreditOptions
      ? {
          creditHistoryType: yup
            .string()
            .oneOf(['credit', 'records'], `${t('consentStep.validate.creditHistoryType')}`)
            .required(`${t('consentStep.validate.creditHistoryType')}`)
        }
      : {})
  });
};

const Hint = styled.div`
  font-size: 0.81em;
  margin-top: 5px;
`;
const currentDate = new Date();

export default ({ onNext, application, location, saveApplicationPart, onBack }) => {
  const { companyContext } = React.useContext(CompanyContext);
  const { t } = useTranslation();

  const displayAltCreditOptions = showAlternativeCreditOptions(application, location);

  const translations = {
    SSN: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.SSN`),
    SSNrequired: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.SSNrequired`),
    SSNmatch: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.SSNmatch`),
    DOBSSN: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.DOBSSN`),
    showSSN: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.showSSN`),
    modifySSN: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.modifySSN`),
    SSNRepeat: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.SSNRepeat`),
    consented: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.consented`),
    description: t(`ssnInfo.${companyContext?.primaryCountry || 'US'}.backgroundCheck_description`),
    alternativeCreditOptions: t(`ssnInfo.alternative_credit_option`),
    alternativeCreditOption_credit: t(`ssnInfo.alternative_credit_option_credit`),
    alternativeCreditOptions_records: t(`ssnInfo.alternative_credit_option_records`),
    alternative_credit_consent: ({ payStubCount, disableBankScreening, bankStatementCount }) => {
      return [
        t(`ssnInfo.alternative_credit_consent1`),
        payStubCount,
        t('ssnInfo.alternative_credit_consent2'),
        !disableBankScreening
          ? t(`ssnInfo.alternative_credit_consent3_verifast`)
          : [
              t(`ssnInfo.alternative_credit_consent3_bank1`),
              bankStatementCount,
              t(`ssnInfo.alternative_credit_consent3_bank2`)
            ].join(' ')
      ].join(' ');
    }
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        consented: true,
        creditHistoryType: application?.consent?.creditHistoryType,
        showSSN: !application.secure?.SSN_SET,
        showDOB: !application.secure?.DOB_SET,
        firstName: application.applicant?.firstName,
        lastName: application.applicant?.lastName,
        middleName: application.applicant?.middleName
      },
      validationSchema: schema(t, translations, displayAltCreditOptions),
      onSubmit: () => {
        const vals = {
          consent: {
            ...(application.consent || {}),
            consented: values.consented,
            creditHistoryType: values.creditHistoryType
          },
          applicant: {
            ...(application.applicant || {}),
            firstName: values.firstName,
            lastName: values.lastName
          }
        };
        if (values.SSN) {
          vals.SSN = values.SSN;
        }
        if (values.DOB) {
          vals.DOB = values.DOB;
        }
        saveApplicationPart({ ...vals }).then(() => {
          onNext();
        });
      }
    });

  const showCreditApproval = !displayAltCreditOptions || values.creditHistoryType === 'credit';

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('backgroundCheck.title')}</Title>
        </Row>
        <Row spacing={25}>
          <Description className="text-center">{translations.description}</Description>
        </Row>
        <Row spacing={60}>
          <RowGroup columns="1fr 1fr 1fr">
            <FormGroup label={t('backgroundCheck.firstName')} errorText={errors.firstName}>
              <Input
                name="firstName"
                value={values.firstName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup label={t('form.legalMiddleName')} errorText={errors.middleName}>
              <Input
                name="middleName"
                value={values.middleName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup label={t('backgroundCheck.lastName')} errorText={errors.lastName}>
              <Input
                name="lastName"
                value={values.lastName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </RowGroup>
          <FormGroup>
            <Hint>{t('backgroundCheck.nameDetails')}</Hint>
          </FormGroup>
          <FormGroup label={t('backgroundCheck.showDOB')} errorText={errors.DOB}>
            <div>
              {values.showDOB && (
                <InputDate
                  name="DOB"
                  value={values.DOB || ''}
                  onChange={handleChange}
                  disabledDays={(date) => {
                    return date > currentDate;
                  }}
                />
              )}
              {!values.showDOB && (
                <Button variant="link" onClick={() => setFieldValue('showDOB', !values.showDOB)}>
                  <i className="fa-solid fa-plus" />
                  &nbsp;{t('backgroundCheck.modifiDOB')}
                </Button>
              )}
            </div>
          </FormGroup>
          <FormGroup label={translations.SSN} errorText={errors.SSN}>
            <div>
              {values.showSSN && (
                <Input
                  name="SSN"
                  type="password"
                  showStrength={false}
                  value={values.SSN || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
              {!values.showSSN && (
                <Button variant="link" onClick={() => setFieldValue('showSSN', !values.showSSN)}>
                  <i className="fa-solid fa-plus" />
                  &nbsp;{translations.modifySSN}
                </Button>
              )}
            </div>
          </FormGroup>
          {values.showSSN && (
            <FormGroup label={translations.SSNRepeat} errorText={errors.SSNRepeat}>
              <Input
                name="SSNRepeat"
                type="password"
                showStrength={false}
                value={values.SSNRepeat || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          )}
          {!!displayAltCreditOptions && (
            <Row>
              <FormGroup
                label={translations.alternativeCreditOptions}
                errorText={touched.creditHistoryType && errors.creditHistoryType}>
                <Select
                  name={'creditHistoryType'}
                  value={values.creditHistoryType}
                  onChange={handleChange}
                  onBlur={handleBlur}>
                  <option value="select">{t('buttonsActions.selectButoon')}</option>
                  <option value="credit">{translations.alternativeCreditOption_credit}</option>
                  <option value="records">{translations.alternativeCreditOptions_records}</option>
                </Select>
              </FormGroup>
            </Row>
          )}
          {showCreditApproval && (
            <FormGroup>
              <div style={{ textAlign: 'center' }}>{translations.consented}</div>
            </FormGroup>
          )}
          {!showCreditApproval && values.creditHistoryType === 'records' && (
            <FormGroup>
              <div style={{ textAlign: 'center' }}>
                {translations.alternative_credit_consent({
                  payStubCount: location?.companyInfo?.payStubCount || 1,
                  disableBankScreening: location?.companyInfo?.disableBankScreening,
                  bankStatementCount: location?.companyInfo?.bankStatementCount || 1
                })}
              </div>
            </FormGroup>
          )}
        </Row>
        <Row className="flex gap-10 center">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button
            disabled={!values.consented}
            radius="25px"
            variant="primary"
            onClick={handleSubmit}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
