import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
const default_countryCode = 'US';

export const validatePhoneNumber = (phone, countryCode) => {
  return isValidNumber(phone, countryCode || default_countryCode);
};

const default_allowedCountries = ['US', 'CA', 'PR', 'BM', 'CU', 'DO', 'MX'];
export const isAllowedCountry = (phone, countryCode, countries = default_allowedCountries) => {
  try {
    if (!phone || !isValidNumber(phone, countryCode || default_countryCode)) return false;
    const num = parsePhoneNumber(phone, countryCode || default_countryCode);
    return !!(countries || default_allowedCountries).includes(num?.country);
  } catch (err) {
    console.error(err);
  }
  return false;
};
